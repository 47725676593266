import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { ngbDateStructFromUnixTimestamp } from 'src/app/common/utilities/date-helpers';
import {
  CoachingSessionDTO,
  NewCoachingSessionItemsPayloadDTO,
} from '../../../dtos/coaching-session.dto';
import { AlertService } from '../../../services/alert/alert.service';
import { CoachingLogService } from '../../../services/coaching-log/coaching-log.service';
import {
  DatepickerComponent,
  DatepickerOutput,
} from '../../datepicker/datepicker.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-duplicate-session-modal',
  templateUrl: './duplicate-session-modal.component.html',
  styleUrl: './duplicate-session-modal.component.scss',
})
export class DuplicateSessionModalComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('modal') modal: ModalComponent;

  @ViewChild('datepicker') datepicker: DatepickerComponent;

  @Input() session: CoachingSessionDTO;

  @Input() user: User | null;

  @Output() readonly sessionDuplicated: EventEmitter<boolean> =
    new EventEmitter();

  endsOption: 'on' | 'after' = 'on';

  days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  selectedDays: string[] = [];

  weekInterval = 1;

  occurrences = 1;

  endDate: number;

  isLoading = false;

  minDate: Date = new Date();

  subs: Subscription[] = [];

  datetimeCount = 0;

  constructor(
    private coachingLogService: CoachingLogService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.coachingLogService.coachlogDuplicated.subscribe((res) => {
        if (res) {
          this.isLoading = false;
          this.alertService.showAlert('Session Duplicated');
          this.modal.close();
          this.sessionDuplicated.next(true);
          this.resetModal();
          this.coachingLogService.coachlogDuplicated.next(false);
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['session']) {
      this.setDefaultDates();
    }
  }

  setDefaultDates() {
    if (this.session && this.datepicker) {
      this.endDate = this.session.startDatetime + 60 * 60 * 24 * 30;
      this.datepicker.selectedDate = ngbDateStructFromUnixTimestamp(
        this.endDate
      );
      this.minDate = new Date(this.session.startDatetime * 1000);
      this.datepicker.minDate = this.minDate;
    }
  }

  onDayClick(day: string) {
    if (this.isDaySelected(day)) {
      this.selectedDays = this.selectedDays.filter(
        (currentDay) => currentDay !== day
      );
    } else {
      this.selectedDays.push(day);
    }
  }

  isDaySelected(day: string) {
    return this.selectedDays.includes(day);
  }

  toggleEndsOption(option: 'on' | 'after') {
    this.endsOption = option;
  }

  onOccurrencesChange(value: number) {
    // Ensure that only a maximum of 2 digits are allowed
    if (value?.toString().length > 2) {
      setTimeout(() => {
        this.occurrences = parseInt(value.toString().slice(0, 2));
      });
    }
  }

  setEndDate(date: DatepickerOutput[]) {
    const selectedEndDate = date[0].time / 1000;
    if (
      this.session &&
      selectedEndDate < this.session.startDatetime - 60 * 60 * 24
    ) {
      this.datepicker.selectedDate = ngbDateStructFromUnixTimestamp(
        this.endDate
      );
    } else {
      this.endDate = date[0].time / 1000;
    }
  }

  formIsValid() {
    return (
      this.selectedDays.length > 0 &&
      this.weekInterval >= 1 &&
      Number.isInteger(this.occurrences) &&
      this.occurrences > 0
    );
  }

  createSessions() {
    if (this.user) {
      const payload = {} as NewCoachingSessionItemsPayloadDTO;
      payload.title = this.session.title;
      payload.logType = this.session.type;
      payload.timezone = this.session.timezone;
      payload.coachUserId = this.user.id;
      payload.creatorUserId = this.user?.id;
      payload.coachees = this.session.attendees.map((attendee) => ({
        id: attendee.userId,
        profile: attendee.user.profile,
      }));
      payload.shadowers = this.session.shadowers.map((shadower) => ({
        id: shadower.userId,
        profile: shadower.user.profile,
      }));
      payload.is_private = !!this.session.isPrivate;
      payload.datetimes = [];
      let currentStartDate = this.session.startDatetime;
      let currentEndDate = this.session.endDatetime;
      let currentDayOfWeek: string;
      const ONE_DAY = 60 * 60 * 24;
      let currentOffset = new Date(currentStartDate * 1000).getTimezoneOffset();
      if (this.endsOption === 'on') {
        while (currentStartDate <= this.endDate) {
          currentStartDate += ONE_DAY;
          currentEndDate += ONE_DAY;
          const newOffset = new Date(
            currentStartDate * 1000
          ).getTimezoneOffset();
          if (newOffset !== currentOffset) {
            const offsetDifference = currentOffset < newOffset ? 3600 : -3600;
            currentStartDate += offsetDifference;
            currentEndDate += offsetDifference;
            currentOffset = newOffset;
          }

          currentDayOfWeek =
            this.days[new Date(currentStartDate * 1000).getDay()];
          if (this.selectedDays.includes(currentDayOfWeek)) {
            payload.datetimes.push({
              start_datetime: currentStartDate,
              end_datetime: currentEndDate,
            });
            this.datetimeCount += 1;
          }
          if (currentDayOfWeek === 'Saturday' && this.weekInterval > 1) {
            currentStartDate += ONE_DAY * 7 * (this.weekInterval - 1);
            currentEndDate += ONE_DAY * 7 * (this.weekInterval - 1);
          }
        }
      } else {
        let occurrences = this.occurrences;
        while (occurrences) {
          currentStartDate += ONE_DAY;
          currentEndDate += ONE_DAY;
          const newOffset = new Date(
            currentStartDate * 1000
          ).getTimezoneOffset();
          if (newOffset !== currentOffset) {
            const offsetDifference = currentOffset < newOffset ? 3600 : -3600;
            currentStartDate += offsetDifference;
            currentEndDate += offsetDifference;
            currentOffset = newOffset;
          }
          currentDayOfWeek =
            this.days[new Date(currentStartDate * 1000).getDay()];
          if (this.selectedDays.includes(currentDayOfWeek)) {
            payload.datetimes.push({
              start_datetime: currentStartDate,
              end_datetime: currentEndDate,
            });
            this.datetimeCount += 1;
            occurrences -= 1;
          }
          if (currentDayOfWeek === 'Saturday' && this.weekInterval > 1) {
            currentStartDate += ONE_DAY * 7 * (this.weekInterval - 1);
            currentEndDate += ONE_DAY * 7 * (this.weekInterval - 1);
          }
        }
      }
      if (payload.datetimes.length > 0) {
        this.isLoading = true;
        this.coachingLogService.createCoachinglog(payload);
      } else {
        this.alertService.showAlert(
          'No sessions will be duplicated with selected settings'
        );
      }
    }
  }

  resetModal() {
    this.endsOption = 'on';
    this.weekInterval = 1;
    this.occurrences = 1;
    this.selectedDays = [];
    this.datetimeCount = 0;
    this.setDefaultDates();
  }

  ngOnDestroy(): void {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
