<div class="card border border-tertiary border-3 text-center p-3 h-100">
  <div class="card-body d-flex flex-column justify-content-center">
    <i
      *ngIf="showRemovalIcon"
      (click)="removeItem()"
      class="top-icon fs-25 bi bi-x-circle removal-icon"></i>
    <div class="top-right-icons d-flex align-items-center">
      <img
        *ngIf="resource.e2lRecommended"
        ngbTooltip="e2L-Recommended"
        src="assets/e2l-logo.png"
        class="recommended-logo me-1"
        alt="e2L"
        role="image" />
      <i
        *ngIf="resource.districtApproved"
        ngbTooltip="District-Approved"
        class="bi bi-building-check approved-logo color-level-4 me-1"></i>
      <app-folder-bookmark
        [itemType]="'elearn_resource'"
        [itemInAFolder]="resource.isOnUsersShelf"
        [itemId]="resource.id"></app-folder-bookmark>
    </div>
    <i [ngClass]="[iconString, 'bi', 'fs-35px']"></i>
    <a
      target="_blank"
      [href]="'/resources/' + resource.slug"
      class="h5 fs-16 fw-bold title">
      {{ resource.title }}
      <i
        class="preview-icon bi bi-box-arrow-in-up-right"
        ngbTooltip="Preview"></i>
    </a>
    <p class="card-text fs-11" [innerHTML]="description"></p>
  </div>
</div>
