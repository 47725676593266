<div class="vid-wrapper position-relative">
  <video
    #videoPlayer
    [src]="video.vimeoLink || esuiteVideoLink"
    class="video-js vjs-big-play-centered vjs-show-big-play-button-on-pause"
    controls
    playsinline
    preload="auto"
    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
    <source
      #videoSource
      type="video/mp4"
      *ngIf="video"
      [src]="video.vimeoLink || esuiteVideoLink" />
    <source
      type="video/ogg"
      *ngIf="video"
      [src]="video.vimeoLink || esuiteVideoLink" />
    <source
      type="video/quicktime"
      *ngIf="video"
      [src]="video.vimeoLink || esuiteVideoLink" />
    <source
      type="video/webm"
      *ngIf="video"
      [src]="video.vimeoLink || esuiteVideoLink" />
    <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to a
      web browser that
      <a href="https://videojs.com/html5-video-support/" target="_blank"
        >supports HTML5 video</a
      >
    </p>
  </video>
</div>
