import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import videojs from 'video.js';

import { VideoPlayerService } from '../../services/video-player/video-player.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() video: VideoDTO;

  @Input() shouldRestart: boolean;

  @Input() isPlaying: boolean;

  @ViewChild('videoPlayer', { static: true })
  videoPlayer: ElementRef;

  player: videojs.Player;

  esuiteVideoLink: string;

  constructor(private videoPlayerService: VideoPlayerService) {}

  // eslint-disable-next-line class-methods-use-this
  getEsuiteVideoLink(link: string) {
    return `${EnvironmentService.apiUrl()}${link}`;
  }

  ngOnInit(): void {
    if (this.video) {
      if (this.video.esuiteLink && !this.video.processedByVimeo) {
        this.esuiteVideoLink = this.getEsuiteVideoLink(this.video.esuiteLink);
      }
    }
  }

  ngAfterViewInit() {
    this.player = videojs(this.videoPlayer.nativeElement);
    this.loadClosedCaptions();
  }

  ngOnChanges() {
    if (this.shouldRestart) {
      this.player.currentTime(0);
      this.player.pause();
    }
    if (this.isPlaying) {
      this.player.play();
    }
  }

  loadClosedCaptions() {
    if (this.video) {
      this.videoPlayerService
        .getClosedCaptions(this.video.id)
        .subscribe((response) => {
          if (response.data) {
            this.player.addRemoteTextTrack(
              { src: response.data, srclang: 'English', mode: 'hidden' },
              false
            );
          }
        });
    }
  }
}
