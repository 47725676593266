<div class="d-flex justify-content-between">
  <div class="fw-800" style="font-size: 2rem">Search Resources</div>
  <div class="form-check form-switch">
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      id="flexSwitchCheckDefault"
      [(ngModel)]="isRestricted"
      (ngModelChange)="updateRestriction()" />

    <label class="form-check-label" for="flexSwitchCheckDefault"
      >My Saved Resources</label
    >
  </div>
</div>

<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-select
  [items]="resourceList$ | async"
  bindLabel="title"
  [addTag]="false"
  [hideSelected]="true"
  [trackByFn]="trackByFn"
  [minTermLength]="2"
  [loading]="isLoading"
  typeToSearchText="Please enter 2 or more characters"
  [typeahead]="searchInput$"
  [(ngModel)]="selectedResource"
  (change)="changedSelection($event)"
  [placeholder]="placeholder"
  [labelForId]="labelId">
  <ng-template ng-option-tmp let-item="item">
    <div class="d-flex align-items-center">
      {{ item.title }}
      <img
        *ngIf="item.e2lRecommended"
        ngbTooltip="e2L-Recommended"
        src="assets/e2l-logo.png"
        alt="e2l"
        class="recommended-logo ms-1" />
      <i
        *ngIf="item.districtApproved"
        ngbTooltip="District-Approved"
        class="bi bi-building-check approved-logo color-level-4 ms-1"></i>
    </div>
  </ng-template>
</ng-select>
