<ng-container *ngIf="resources && resources.length > 0; else noResources">
  <div class="row g-3 card-group">
    <div
      class="col col-xs-1 col-sm-6 col-xl-4"
      *ngFor="let resource of resources">
      <app-resource-preview
        [resource]="resource"
        [showRemovalIcon]="showRemovalIcon"
        [userDistrictId]="userDistrictId"
        (signalRemoval)="signalRemoval.emit($event)"></app-resource-preview>
    </div>
  </div>
</ng-container>

<ng-template #noResources>
  <div
    class="d-flex w-100 align-items-center justify-content-center"
    style="height: 70px">
    Selected resources will be added here.
  </div>
</ng-template>
