import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { CompetencyDTO } from 'src/app/private/shared/dtos/competencies.dto';
import { ResourceFiltersDTO } from '../../../dtos/resources.dto';
import { TagDTO } from '../../../dtos/tags.dto';
import { ResourcesSearchService } from '../../../services/resources/resources-search.service';
import { CompetencySelectComponent } from '../../competency/competency-select/competency-select.component';
import { DistrictSelectComponent } from '../../district-select/district-select.component';
import { ModalComponent } from '../../modals/modal/modal.component';

interface AuthorId {
  name: string;
  id: number;
}

@Component({
  selector: 'app-resource-filter-modal',
  templateUrl: './resource-filter-modal.component.html',
  styleUrls: ['./resource-filter-modal.component.scss'],
})
export class ResourceFilterModalComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('resourceFilterModal') resourceFilterModal: ModalComponent;

  @ViewChild('selectCompetency')
  selectCompetency: CompetencySelectComponent;

  @ViewChild('districtSelect') districtSelect: DistrictSelectComponent;

  @Input() user: User | null;

  @Input() filterType: string;

  @Output() readonly currentAuthorsEvent = new EventEmitter<AuthorId[]>();

  @Output() readonly currentCompetenciesEvent = new EventEmitter<
    CompetencyDTO[]
  >();

  @Output() readonly clearFiltersEvent = new EventEmitter<void>();

  currentTags: TagDTO[] = [];

  currentCompetencies: CompetencyDTO[] = [];

  currentAuthors: AuthorId[] = [];

  editedFilters: ResourceFiltersDTO;

  isE2l = false;

  districtIds: number[] = [];

  resourceTypeSelectValues = [
    { label: 'All', value: '' },
    { label: 'Research', value: 'research' },
    { label: 'Template', value: 'template' },
    { label: 'DIY', value: 'diy' },
    { label: 'Workshop', value: 'workshop' },
    { label: 'Video', value: 'video' },
  ];

  createdBySelectValues = [
    { label: 'Anyone', value: '' },
    { label: 'e2L Admin', value: 'e2l' },
    { label: 'My District', value: 'district' },
    { label: 'Me', value: 'me' },
    { label: 'Specific User(s)', value: 'specific' },
  ];

  subs: Subscription[] = [];

  e2lRecommended = false;

  districtApproved = false;

  constructor(private resourceService: ResourcesSearchService) {}

  ngOnInit() {
    this.subs.push(
      this.resourceService.editedSearchFilters.subscribe((editedFilters) => {
        this.editedFilters = deepCopy(editedFilters);
      })
    );
    if (
      this.user?.district &&
      !this.user.roles.includes(UserRole.E2L_EMPLOYEE || UserRole.ESUITE_ADMIN)
    ) {
      this.districtIds.push(this.user.district.id);
    }
    if (this.user) {
      this.isE2l = checkIfE2L(this.user);
    }
  }

  ngAfterViewInit() {
    this.resourceFilterModal.isOpening.subscribe(() => {
      this.resourceService.resetEditedFilters();
      if (this.editedFilters.endorsed_districts) {
        this.e2lRecommended =
          this.editedFilters.endorsed_districts?.includes(2);
        if (
          this.editedFilters.endorsed_districts.length < 1 &&
          !this.editedFilters.endorsed_districts.includes(2)
        ) {
          this.districtApproved = false;
          if (this.districtSelect) this.districtSelect.reset();
        }
      }
      if (
        this.currentAuthors.length > 0 &&
        this.editedFilters.created_by === ''
      ) {
        this.editedFilters.created_by = 'specific';
      }
    });
  }

  applyFilters() {
    const tags = this.currentTags.map((tag) => tag.tag);
    const competencies = this.currentCompetencies.map(
      (competency) => competency.id
    );

    this.editedFilters.tags = tags;
    this.editedFilters.competencies = competencies;

    // Template only uses value 'specific' to hide & show user search; set it to empty string when we apply filters
    if (this.editedFilters.created_by === 'specific') {
      this.editedFilters.created_by = '';
    }

    this.resourceService.setEditedFilters(this.editedFilters);

    this.resourceService.applyEditedFilters();

    this.currentAuthorsEvent.emit(this.currentAuthors);
    this.currentCompetenciesEvent.emit(this.currentCompetencies);

    this.resourceFilterModal.close();
  }

  resetFilters() {
    this.resourceService.resetFilters();
    this.currentAuthors = [];
    this.currentCompetencies = [];
    this.selectCompetency.reset();
    this.currentTags = [];
    this.editedFilters.type = '';
    this.editedFilters.created_by = '';
    this.editedFilters.levels = [];
    this.editedFilters.endorsed_districts = [];
    this.e2lRecommended = false;
    this.districtApproved = false;
  }

  addUser(incomingUser: UserLiteDTO | null) {
    if (incomingUser && this.editedFilters.authors) {
      if (
        !this.editedFilters.authors.some(
          (authorId) => incomingUser.id === authorId
        )
      ) {
        this.editedFilters.authors.push(incomingUser.id);
        this.currentAuthors.push({
          id: incomingUser.id,
          name: `${incomingUser.profile.first_name} ${incomingUser.profile.last_name}`,
        });
      }
    }
  }

  addTag(incomingTag: TagDTO) {
    if (incomingTag) {
      if (incomingTag !== undefined || incomingTag !== null) {
        if (!this.currentTags.some((item) => incomingTag.id === item.id)) {
          this.currentTags.push(incomingTag);
        }
      }
    }
  }

  addCompetencies(competencies: CompetencyDTO[] | null) {
    if (competencies) {
      if (competencies !== undefined || competencies !== null) {
        this.currentCompetencies = competencies;
      }
    } else {
      this.currentCompetencies = [];
    }
  }

  removeUser(author: AuthorId) {
    this.currentAuthors = this.currentAuthors.filter(
      (currentAuthor: AuthorId) => currentAuthor !== author
    );
  }

  removeTag(tagName: string) {
    this.currentTags = this.currentTags.filter((tag) => tag.tag !== tagName);
  }

  removeCompetency() {
    this.currentCompetencies = [];
    this.selectCompetency.internalSelectedCompetency = null;
  }

  onCheckboxChange(event: Event): void {
    const target = event.target as HTMLInputElement | null;

    if (target) {
      if (target.checked) {
        this.editedFilters.levels?.push(Number(target.value));
      } else {
        const matchedLevel = this.editedFilters.levels?.find(
          (level) => level === Number(target.value)
        );
        this.editedFilters.levels = this.editedFilters.levels?.filter(
          (level) => level !== matchedLevel
        );
      }
    }
  }

  onEndorsementChange(event: Event, type: 'e2l' | 'district') {
    const target = event.target as HTMLInputElement;
    if (type === 'e2l') {
      this.updateEndorsedDistricts(target.checked, 2);
      this.e2lRecommended = target.checked;
    } else if (!this.isE2l) {
      const userDistrictId = this.user?.district?.id;
      this.updateEndorsedDistricts(target.checked, userDistrictId);
      this.districtApproved = target.checked;
    } else {
      this.districtApproved = target.checked;
    }
  }

  updateEndorsedDistricts(checked: boolean, districtId?: number) {
    if (!districtId) return;

    const endorsedDistricts = this.editedFilters.endorsed_districts;

    if (checked) {
      if (!endorsedDistricts?.includes(districtId)) {
        endorsedDistricts?.push(districtId);
      }
    } else {
      this.editedFilters.endorsed_districts = endorsedDistricts?.filter(
        (id) => id !== districtId
      );
    }
  }

  onEndorsedDistrictListEvent(districtList: DistrictSimpleDto[]) {
    this.editedFilters.endorsed_districts = districtList.map(
      (district) => district.id
    );
    if (
      this.e2lRecommended &&
      !this.editedFilters.endorsed_districts.includes(2)
    )
      this.editedFilters.endorsed_districts.push(2);
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
