<ng-container *ngIf="indicatorSet && indicatorSet.indicatorSet">
  <ng-container *ngFor="let group of indicatorSet.indicatorSet.indicatorGroups">
    <app-builder-leveled-competency-strand
      (deleteStrandEvent)="deleteCompetencyEvent.emit($event)"
      (badgeGraphicUploadedEvent)="badgeGraphicUploadedEvent.emit()"
      (updatingEvent)="updatingEvent.emit($event)"
      [strand]="group"
      [level1name]="indicatorSet.indicatorSet.level1_name"
      [level2name]="indicatorSet.indicatorSet.level2_name"
      [level3name]="indicatorSet.indicatorSet.level3_name"
      [level4name]="indicatorSet.indicatorSet.level4_name"
      [apiLoading]="apiLoading" />
  </ng-container>
</ng-container>
