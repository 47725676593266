<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-container>
  <ng-select
    [labelForId]="labelId"
    (change)="districtSelected($event); select.blur()"
    [items]="districtList | async"
    bindLabel="title"
    [clearable]="!simpleSelect"
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="searchInput$"
    [loading]="searchLoading"
    [(ngModel)]="internalSelectedDistrict"
    [placeholder]="placeholder"
    [clearable]="clearable"
    #select>
  </ng-select>
  <ng-container
    *ngIf="internalSelectedDistrictList && internalSelectedDistrictList.length">
    <div>
      <ng-container *ngFor="let eachDistrict of internalSelectedDistrictList">
        <span
          class="badge badge-item mt-2 me-1"
          (click)="removeDistrictFromList(eachDistrict)"
          >{{ eachDistrict.title }}<i class="bi bi-x mx-2 me-2"></i
        ></span>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
