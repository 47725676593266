import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject, Subscription, map } from 'rxjs';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { v4 as uuidv4 } from 'uuid';
import { getIdsFromDefaultItemsForSelect } from '../../helpers/select.utilities';
import { UserSearchService } from '../../services/user-search/user-search.service';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  providers: [UserSearchService],
})
export class UserSelectComponent implements OnInit, OnChanges {
  userList: Observable<UserLiteDTO[]>;

  // userLibrary: UserDTO[] = [];

  searchInput$ = new Subject<string>();

  searchSubscription: Subscription | null;

  searchLoading = false;

  labelId = uuidv4();

  internalSelectedUser: UserLiteDTO | null = null;

  internalSelectedUserList: UserLiteDTO[] = [];

  invalid = false;

  @ViewChild('select') select: NgSelectComponent;

  @Input() fieldInvalid = false;

  @Input() hideSelectedUserList = false;

  @Input() clearAfterSelection = false;

  @Input() disabled = false;

  @Input() label = 'Search for and select a user';

  @Input() placeholder = 'Search for a user...';

  @Input() isMulti = false;

  @Input() defaultUsers: UserLiteDTO[] | null;

  @Input() districtId: number | null = null;

  @Input() districtIds: number[];

  @Input() includeAllB2bDistricts = false;

  @Input() includeE2l = false;

  @Input() currentUser: User | null;

  @Output() readonly selectedUser: EventEmitter<UserLiteDTO | null> =
    new EventEmitter();

  @Output() readonly selectedUserList: EventEmitter<UserLiteDTO[] | null> =
    new EventEmitter();

  @Output() readonly handleClearClick = new EventEmitter();

  constructor(private userSearch: UserSearchService) {}

  ngOnInit(): void {
    this.getUserList();

    /* skipcq: JS-0045 */
    // eslint-disable-next-line consistent-return
    this.searchInput$.subscribe((term) => {
      if (term && term.length >= 3) {
        this.searchLoading = true;
        let districtIds: number[] = [];
        if (this.districtId && !this.districtIds) {
          districtIds.push(this.districtId);
        } else if (this.districtIds && !this.districtId) {
          districtIds = this.districtIds;
        }
        return this.userSearch.search(
          term,
          districtIds,
          this.includeAllB2bDistricts,
          this.includeE2l
        );
      }
    });

    this.getDefaultUsers();
  }

  getUserList() {
    this.userList = this.userSearch.items.pipe(
      map((users) => {
        this.searchLoading = false;

        return users.filter((user) => user.id !== this.currentUser?.id);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((propName) => {
      switch (propName) {
        case 'districtId':
          this.getUserList();
          break;
        case 'defaultUsers':
          if (!this.isMulti) {
            this.getDefaultUsers();
          }
          break;
        case 'fieldInvalid':
          this.invalid = this.fieldInvalid;
          break;
        default:
          break;
      }
    });
  }

  handleClear() {
    this.userSearch.clear();
    this.handleClearClick.emit();
  }

  userSelected(user: UserLiteDTO) {
    if (this.isMulti && user) {
      if (!this.internalSelectedUserList.some((item) => item.id === user.id)) {
        this.internalSelectedUserList.push(user);
      }
      this.select.handleClearClick();
    } else {
      this.internalSelectedUser = user;
    }
    this.outputUsers();
    if (this.clearAfterSelection) {
      // Without a timeout you get infinite recursion
      setTimeout(() => {
        this.select.unselect(this.select.selectedItems[0]);
      });
    }
  }

  reset() {
    if (this.isMulti) {
      this.internalSelectedUserList = [];
    } else {
      this.internalSelectedUser = null;
    }
    this.select.selectedItems.forEach((item) => {
      this.select.unselect(item);
    });
  }

  outputUsers() {
    if (this.isMulti) {
      this.selectedUserList.emit(this.internalSelectedUserList);
    } else {
      this.selectedUser.emit(this.internalSelectedUser);
    }
  }

  removeUserFromList(user: UserLiteDTO) {
    this.internalSelectedUserList = this.internalSelectedUserList.filter(
      (u) => u.id !== user.id
    );
    this.outputUsers();
  }

  getDefaultUsers() {
    if (this.defaultUsers) {
      const userIds = getIdsFromDefaultItemsForSelect(this.defaultUsers);
      userIds.forEach((userId) => {
        this.userSearch.getUser(userId as number).subscribe((user) => {
          if (user) {
            if (this.isMulti) {
              this.internalSelectedUserList.push(user);
            } else {
              this.internalSelectedUser = user;
            }
          }
        });
      });
    }
  }
}
