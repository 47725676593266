<app-modal
  #modal
  [modalConfig]="{
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    showFooter: false
  }"
  (isClosing)="clearForm()"
  (isDismissing)="clearForm()">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <ng-container *ngIf="form">
    <form [formGroup]="form">
      <label for="plan-title" class="form-label">{{ displayName }} Name:</label>
      <div class="input-group mb-2">
        <input
          formControlName="title"
          type="text"
          class="form-control"
          id="plan-title"
          aria-label="Plan Title"
          [ngClass]="{
            'is-invalid': formSubmitted && f['title'].errors
          }" />
      </div>
      <label for="plan-audience" class="form-label">Description:</label>
      <div class="input-group mb-2">
        <textarea
          formControlName="description"
          type="text"
          class="form-control"
          id="plan-description"
          aria-label="Plan Description"
          [ngClass]="{
            'is-invalid': formSubmitted && f['description'].errors
          }"></textarea>
      </div>
      <ng-container *ngIf="isEditMode && phaseToEdit">
        <label for="plan-title" class="form-label">Status:</label>
        <div class="input-group mb-2">
          <app-plans-status-select
            #statusSelect
            [status]="phaseToEdit.status"></app-plans-status-select>
        </div>
      </ng-container>
      <div class="mt-4">
        <label for="assigneesSelect" class="form-label">Assignee(s):</label>
        <app-user-select
          *ngIf="currentUser.district && planDistrictId"
          #assigneesSelect
          [districtIds]="
            isE2L ? [planDistrictId, currentUser.district.id] : [planDistrictId]
          "
          [clearAfterSelection]="true"
          (selectedUser)="addChosenUser($event)"
          [isMulti]="false"></app-user-select>
        <ng-container *ngFor="let user of assignees">
          <app-new-coaching-attendee
            [user]="user"
            (removedAttendee)="deleteChosenUser(user)" />
        </ng-container>
      </div>
      <div *ngIf="serverError" class="mt-3">
        <ngb-alert [dismissible]="false" [type]="'danger'">
          <strong>Error</strong> {{ serverError }}
        </ngb-alert>
      </div>
      <div class="mt-5 d-flex justify-content-between">
        <ng-container *ngIf="isEditMode; else cancelButtonTemplate">
          <button
            aria-label="delete button"
            class="btn btn-delete"
            type="button"
            [autoClose]="'outside'"
            [ngbPopover]="deletePopoverContent"
            [popoverTitle]="'Delete ' + displayName"
            #popover="ngbPopover">
            Delete
          </button>
          <ng-template #deletePopoverContent>
            Permanently delete this {{ displayName.toLowerCase() }}?
            <div class="text-end mt-2">
              <button
                type="button"
                class="btn btn-secondary btn-sm px-3 m-1"
                (click)="popover.close()">
                No
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm px-3 m-1"
                (click)="popover.close(); deletePhase()">
                Yes
              </button>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #cancelButtonTemplate>
          <button class="btn" (click)="modal.close()">Cancel</button>
        </ng-template>
        <button
          class="btn btn-primary"
          type="submit"
          [disabled]="submitLoading"
          (click)="onSubmit()">
          <span
            *ngIf="submitLoading"
            class="spinner-border spinner-border-sm me-1"></span>
          {{ isEditMode ? "Save" : "Create" }}
        </button>
      </div>
    </form>
  </ng-container>
</app-modal>
