import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { EnvironmentService } from '../../../../common/services/environment/environment.service';
import { CodoxConfig, CodoxService } from '../../services/codox/codox.service';

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface FroalaOptions {
  attribution?: boolean;
  key?: string;
  inlineMode?: boolean;
  events?: any;
  width?: number;
  height?: number;
  enter?: FroalaEnterOutput;
  toolbarButtons?: any;
  linkEditButtons?: any;
  pluginsEnabled?: any;
  htmlSimpleAmpersand?: boolean;
  pastePlain?: boolean;
  initialContent?: string;
  editorClass?: string;
  toolbarBottom?: boolean;
  fontFamilySelection?: boolean;
  fontSizeSelection?: boolean;
  paragraphFormatSelection?: boolean;
  docId?: string;
  charCounterCount?: boolean;
  quickInsertEnabled?: boolean;
  placeholderText?: string;
  emoticonsSet?: any;
  imageUploadURL?: string;
  wordCounterCount?: boolean;
  toolbarInline?: boolean;
  toolbarVisibleWithoutSelection?: boolean;
  toolbarInlineClass?: string;
  toolbarContainer?: string;
}

export interface WysiwygEditorConfig extends FroalaOptions {
  editId?: string;
  useCodox?: boolean;
}

export enum FroalaEnterOutput {
  'INSERT_P' = 0,
  'INSERT_DIV' = 1,
  'INSERT_BR' = 2,
}

/* eslint-disable-next-line */
declare let Codox: any;

@Component({
  selector: 'app-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss'],
  providers: [CodoxService],
})
export class WysiwygEditorComponent implements OnInit, OnChanges {
  user: User;

  @ViewChild('editor') private editor: ElementRef;

  @Output() readonly editorContentEvent = new EventEmitter<string>();

  @Input() disabled = false;

  @Input()
  public config: WysiwygEditorConfig;

  @Input()
  public editorContent: string;

  @Input()
  public isSaving = false;

  @Input() maxHeight: number;

  @Output() public readonly isSavingChange = new EventEmitter<boolean>();

  @Output() readonly isTypingEvent = new EventEmitter<boolean>();

  @Input() version: 'none' | 'mini' | 'full' | 'custom';

  @Input() customOptions: FroalaOptions;

  public froalaOptions: FroalaOptions;

  public debounceTimeout?: ReturnType<typeof setTimeout>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private froalaEditorDirective: any;

  constructor(private codoxService: CodoxService, private store: Store) {
    this.user = store.selectSnapshot(UserState.getUser) as User;
  }

  defaultFroalaOptions: FroalaOptions = {
    attribution: false,
    key: EnvironmentService.froalaKey,
    charCounterCount: false,
    inlineMode: false,
    quickInsertEnabled: false,
  };

  noneFroalaOptions: FroalaOptions = {
    toolbarButtons: {},
    linkEditButtons: [],
    pluginsEnabled: [],
    editorClass: 'none',
  };

  miniFroalaOptions: FroalaOptions = {
    toolbarButtons: {
      moreText: {
        buttons: ['bold', 'italic', 'underline'],
        buttonsVisible: 5,
      },
      moreParagraph: {
        buttons: ['formatOL', 'formatUL'],
        buttonsVisible: 2,
      },
      moreRich: {
        buttons: ['insertLink', 'emoticons'],
        buttonsVisible: 2,
      },
    },
    editorClass: 'mini',
  };

  fullFroalaOptions: FroalaOptions = {
    imageUploadURL: `${EnvironmentService.apiUrl()}/uploads`,
    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'fontFamily',
          'fontSize',
          'textColor',
          'backgroundColor',
          'strikeThrough',
          'subscript',
          'superscript',
          'clearFormatting',
        ],
      },
      moreParagraph: {
        buttons: [
          'alignLeft',
          'alignCenter',
          'alignRight',
          'formatOL',
          'formatUL',
          'paragraphFormat',
          'paragraphStyle',
          'lineHeight',
          'outdent',
          'indent',
          'quote',
        ],
        buttonsVisible: 4,
      },
      moreRich: {
        buttons: [
          'emoticons',
          'specialCharacters',
          'insertLink',
          'insertTable',
          'insertImage',
          'insertHR',
        ],
        buttonsVisible: 1,
      },
      moreMisc: {
        buttons: [
          'undo',
          'redo',
          'fullscreen',
          'print',
          'spellChecker',
          'selectAll',
        ],
        buttonsVisible: 2,
      },
    },
    editorClass: 'full',
  };

  ngOnInit(): void {
    const events = this.config?.useCodox
      ? {
          initialized: (editor: any) => {
            this.froalaEditorDirective = editor;
            // Codox is loaded as an external dependency and may beat the Froala editor to load
            if (this.disabled) {
              editor._editor.edit.off();
            } else {
              this.startCodox();
            }
            if (this.froalaOptions.toolbarInlineClass) {
              const toolbar = editor._editor.$tb; // Selects the toolbar element
              if (toolbar) {
                toolbar.addClass(this.froalaOptions.toolbarInlineClass); // Add custom inline toolbar class
              }
            }
          },
        }
      : {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          initialized: (editor: any) => {
            this.froalaEditorDirective = editor;
            if (this.disabled) {
              editor._editor.edit.off();
            }
            if (this.froalaOptions.toolbarInlineClass) {
              const toolbar = editor._editor.$tb; // Selects the toolbar element
              if (toolbar) {
                toolbar.addClass(this.froalaOptions.toolbarInlineClass); // Add custom inline toolbar class
              }
            }
          },
          contentChanged: () => {
            this.isSavingChange.emit(true);
            if (this.debounceTimeout) {
              clearTimeout(this.debounceTimeout);
            }
            this.debounceTimeout = setTimeout(() => {
              this.editorContentEvent.emit(this.editorContent);
            }, 350);
          },
          keydown: () => {
            this.isTypingEvent.emit(true);
          },
        };

    this.froalaOptions = {
      ...this.config,
      ...this.defaultFroalaOptions,
      events: {
        ...this.defaultFroalaOptions.events,
        ...events,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        'image.uploaded': (res: any) => {
          const imgUrl = `${EnvironmentService.apiUrl()}${
            JSON.parse(res).location
          }`;
          this.froalaEditorDirective._editor.image.insert(
            imgUrl,
            true,
            null,
            this.froalaEditorDirective._editor.image.get(),
            { link: JSON.parse(res).location }
          );
          return false;
        },
      },
    };

    if (this.version === 'none') {
      this.froalaOptions = {
        ...this.froalaOptions,
        ...this.noneFroalaOptions,
      };
    } else if (this.version === 'mini') {
      this.froalaOptions = {
        ...this.froalaOptions,
        ...this.miniFroalaOptions,
      };
    } else if (this.version === 'custom') {
      this.froalaOptions = {
        ...this.froalaOptions,
        ...this.customOptions,
      };
    } else {
      this.froalaOptions = {
        ...this.fullFroalaOptions,
        ...this.froalaOptions,
      };
    }
    if (!this.editorContent) {
      this.editorContent =
        this.config && this.config.initialContent
          ? this.config.initialContent
          : '';
    }
    if (this.maxHeight) {
      this.froalaOptions.height = this.maxHeight;
    }
    this.froalaOptions.linkEditButtons = ['linkOpen', 'linkEdit', 'linkRemove'];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && this.froalaEditorDirective) {
      if (this.disabled) {
        this.froalaEditorDirective._editor.edit.off();
      } else {
        this.froalaEditorDirective._editor.edit.on();
      }
    }
  }

  resetContent(): void {
    this.editorContent = '';
  }

  startCodox() {
    const codoxConfig: CodoxConfig = {
      docId: `${EnvironmentService.env()}-${this.config.editId}`,
      username: `${this.user?.profile.first_name} ${this.user?.profile.last_name}`,
      // eslint-disable-next-line
      editor: this.froalaEditorDirective._editor,
      hooks: {
        contentChanged: (data: { source: string }) => {
          this.isSavingChange.emit(true);
          if (data.source === 'local') {
            if (this.debounceTimeout) {
              clearTimeout(this.debounceTimeout);
            }
            this.debounceTimeout = setTimeout(() => {
              this.editorContentEvent.emit(
                this.froalaEditorDirective._editor.html.get()
              );
            }, 350);
          }
        },
      },
    };
    this.codoxService.setDetails(codoxConfig);
    this.codoxService.start();
  }
}
