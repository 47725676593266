import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { selectOptionsFromEnum } from 'src/app/common/utilities/enum-helpers';
import { UploadStyles } from 'src/app/private/shared/components/file-management/file-upload/file-upload.component';
import { FormModalComponent } from 'src/app/private/shared/components/forms/view/form-modal/form-modal.component';
import { ModalComponent } from 'src/app/private/shared/components/modals/modal/modal.component';
import { FileDTO } from 'src/app/private/shared/dtos/file.dto';
import {
  Field,
  FieldType,
  FieldTypes,
  Form,
  FormStatus,
  FormType,
  FormTypesMap,
} from 'src/app/private/shared/dtos/forms.dto';
import { FileType } from 'src/app/private/shared/enums/file-type.enum';
import { DistrictListService } from 'src/app/private/shared/services/district-list/district-list-service';
import { FormsService } from 'src/app/private/shared/services/forms/forms.service';
import { v4 as uuidv4 } from 'uuid';

const fieldTypeDisplayNameMap = {
  [FieldType.TEXT]: 'Text field',
  [FieldType.TEXTBLOCK]: 'Text field',
  [FieldType.CHECKBOX]: 'Choose from options',
  [FieldType.RADIO]: 'Choose from options',
  [FieldType.SELECT]: 'Choose from options',
  [FieldType.SECTION]: 'Section Header',
  [FieldType.FREETEXT]: 'Description/instructions',
  [FieldType.COMPETENCY]: 'Leveled look-fors',
  [FieldType.MEDIA]: 'Attachment',
};

@Component({
  selector: 'app-create-form-page',
  templateUrl: '../form/form-page.html',
  styleUrls: ['../form/form-page.scss'],
})
export class CreateFormPageComponent {
  @ViewChild('previewModal') previewModal: FormModalComponent;

  data: Form;

  isSaving = false;

  district: DistrictSimpleDto;

  districtCode: string;

  user: User;

  @ViewChild('AddFieldModal') addFieldModal: ModalComponent;

  fieldChoices = FieldTypes;

  formTypes = FormTypesMap;

  userRoles = UserRole;

  fieldTypeDisplay = fieldTypeDisplayNameMap;

  emptyPrompt = false;

  attachments: FileDTO[] = [];

  uploadStyle = UploadStyles.SIMPLE;

  allowedFileTypes = FileType.IMAGE;

  updateDistrictsSharedWith(districtList: DistrictSimpleDto[]) {
    this.data.districtsSharedWith = districtList.map((d) => d.id);
  }

  updateExcludedDistricts(districtList: DistrictSimpleDto[]) {
    this.data.districtsSharedExclusion = districtList.map((d) => d.id);
  }

  statuses: { key: string; value: string }[];

  constructor(
    public router: Router,
    protected store: Store,
    public formsService: FormsService,
    protected districtListService: DistrictListService,
    protected route: ActivatedRoute
  ) {
    this.statuses = selectOptionsFromEnum(FormStatus).filter(
      (status) => status.key !== FormStatus.COLLECTING
    );
    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.data = {
      isTemplate: false,
      id: 0,
      districtId: 0,
      title: '',
      description: '',
      fields: [],
      status: FormStatus.DRAFT,
      type: FormType.VISIT,
      createdAt: 0,
      modifiedAt: 0,
      author: this.user,
      deletedFields: [],
      image: '',
    };
    this.districtListService
      .fetchDistrictsSimple({ per_page: 1000 })
      .subscribe((districts) => {
        if (districts) {
          const district = districts.find(
            (searchDistrict) =>
              searchDistrict.districtCode === this.districtCode
          ) as DistrictSimpleDto;
          if (district) {
            this.district = district;
          } else {
            this.district = districts.find(
              (searchDistrict) => searchDistrict.id === 2
            ) as DistrictSimpleDto;
            this.districtCode = this.district.districtCode;
          }
          this.data.districtId = this.district.id;
        }
      });
  }

  backToFormsList() {
    this.router.navigate([
      'settings',
      'districts',
      this.district.districtCode,
      'forms',
    ]);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data.fields, event.previousIndex, event.currentIndex);
  }

  changeTemplate() {
    this.data.isTemplate = !this.data.isTemplate;
    if (this.data.isTemplate) {
      this.data.districtsSharedWith = [];
    } else {
      delete this.data.districtsSharedWith;
    }
  }

  openFieldModal() {
    this.addFieldModal.open();
  }

  updateField(field: Field) {
    this.data.fields = this.data.fields.map((f) => {
      if (f.id === field.id) {
        return field;
      }
      return f;
    });
    this.data = { ...this.data };
    this.checkForEmptyPrompt();
  }

  addField(type: FieldType) {
    this.data.fields.push({
      id: uuidv4(),
      name: '',
      type,
      label: '',
    });
    this.emptyPrompt = true;
    this.addFieldModal.close();
  }

  deleteField(id: string) {
    let deleteField: Field | null = null;
    this.data.fields.forEach((field, index) => {
      if (field.id === id) {
        [deleteField] = this.data.fields.splice(index, 1);
      }
    });
    // if the form has submissions against it, any deleted fields will be saved
    if (this.data.hasSubmission && deleteField) {
      this.data.deletedFields?.push(deleteField);
    }
    this.checkForEmptyPrompt();
  }

  deleteForm() {
    this.formsService.deleteForm(this.data.id).subscribe(() => {
      this.backToFormsList();
    });
  }

  deleteAttachment(file: FileDTO) {
    this.attachments = this.attachments.filter(
      (attachment) => attachment.id !== file.id
    );
  }

  updateAttachments(file: FileDTO) {
    this.attachments.push(file);
  }

  saveForm() {
    this.isSaving = true;
    this.formsService
      .createForm({
        isTemplate: this.data.isTemplate,
        status: this.data.status,
        title: this.data.title,
        description: this.data.description,
        fields: this.data.fields,
        districtId: this.district.id,
        type: this.data.type,
        districtsSharedWith: this.data.districtsSharedWith,
        districtsSharedExclusion: this.data.districtsSharedExclusion,
        deletedFields: this.data.deletedFields,
        image: this.attachments[0] ? this.attachments[0].location : '',
      })
      .subscribe(() => {
        this.router.navigate([
          'settings',
          'districts',
          this.districtCode,
          'forms',
        ]);
      });
  }

  checkForEmptyPrompt() {
    this.emptyPrompt = false;
    this.data.fields.forEach((field) => {
      if (!field.label) {
        this.emptyPrompt = true;
      }
    });
  }
}
