import { numberFromNumberOrString } from 'src/app/common/utilities/type-helpers';
import {
  ResourceEndorsementDTO,
  ResourceLeveledListItemDTO,
  ResourceListItemDTO,
} from '../../../dtos/resources.dto';
import { ResourceType } from '../../../enums/resource-type.enum';

export interface ResourcePreviewDTO {
  id: number;
  type: ResourceType;
  title: string;
  description: string;
  isOnUsersShelf: number;
  slug?: string;
  endorsements?: ResourceEndorsementDTO[];
  e2lRecommended?: boolean;
  districtApproved?: boolean;
}

export const resourceListItemDTOToResourcePreviewDTO = (
  resourceDto: ResourceListItemDTO
): ResourcePreviewDTO => ({
  id: numberFromNumberOrString(resourceDto.id),
  type: ResourceType.RESOURCE,
  title: resourceDto.title,
  description: resourceDto.description,
  isOnUsersShelf: resourceDto.isOnUsersShelf,
  endorsements: resourceDto.endorsements,
  slug: resourceDto.slug,
});

export const resourceLeveledListItemDTOToResourcePreviewDTO = (
  resourceDto: ResourceLeveledListItemDTO
): ResourcePreviewDTO => ({
  id: numberFromNumberOrString(resourceDto.id),
  type: ResourceType.RESOURCE,
  title: resourceDto.title,
  description: resourceDto.description,
  isOnUsersShelf: resourceDto.isOnUsersShelf,
  endorsements: resourceDto.endorsements,
  slug: resourceDto.slug,
});
