import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { PresenceChannel } from 'pusher-js';
import { Subscription } from 'rxjs';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  checkIfDistrictOperator,
  checkIfE2LOperator,
} from 'src/app/common/utilities/role-helpers';
import { CompetencyDTO } from 'src/app/private/shared/dtos/competencies.dto';
import { LeveledCompetencyDTO } from '../../shared/components/leveled-resource-competencies/leveled-competency.dto';

import { FileListComponent } from '../../shared/components/file-management/file-list/file-list.component';
import {
  FileUploadComponent,
  UploadStyles,
} from '../../shared/components/file-management/file-upload/file-upload.component';
import { ModalComponent } from '../../shared/components/modals/modal/modal.component';
import { FileDTO } from '../../shared/dtos/file.dto';
import { CompetencyLeveledResourcesDto } from '../../shared/dtos/leveled-resource.dto';
import { ResourceDTO } from '../../shared/dtos/resources.dto';
import { UploadType } from '../../shared/enums/upload-type.enum';
import { AlertService } from '../../shared/services/alert/alert.service';
import { CompetenciesSelectService } from '../../shared/services/competencies/competencies-select.service';
import { DistrictSearchService } from '../../shared/services/district-search/district-search.service';
import { ResourceFileService } from '../../shared/services/file/resource.file.service';
import { ResourceService } from '../../shared/services/resources/resource.service';

@Component({
  selector: 'app-resource-view-page',
  templateUrl: './resource-view-page.component.html',
  styleUrls: ['./resource-view-page.component.scss'],
})
export class ResourceViewPageComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  doneLoading = false;

  resourceSlug: string;

  currentResource: ResourceDTO | null;

  isOwnerOrEditor = false;

  user: User;

  editMode = false;

  isLocked: boolean | null = false;

  isPublished: boolean | null = false;

  channel: PresenceChannel;

  currentCompetency: CompetencyDTO;

  competenciesToAdd: LeveledCompetencyDTO[] | null;

  resourceCompetencies: LeveledCompetencyDTO[] | null;

  invalidSelection = false;

  selectionNotAllowed = false;

  selectionAlreadyAdded = false;

  uploadStyle = UploadStyles.SIMPLE;

  uploadType = UploadType.RESOURCE_ATTACHMENT;

  attachments: FileDTO[] = [];

  districtIds: number[] = [];

  resourceErrorMessage: string;

  endorsedByE2L = false;

  endorsedByDistrict = false;

  endorsedByE2LModel = false;

  endorsedByDistrictModel = false;

  districtEndorsementCount = 0;

  districtsWhoHaveEndorsed: string[] = []; // Names of districts that have endorsed the resource

  userIsE2LOperator = false;

  userIsDistrictOperator = false;

  @ViewChild('uploadComponent') uploadComponent: FileUploadComponent;

  @ViewChild('fileListComponent') fileListComponent: FileListComponent;

  @ViewChild('permissionsModal') permissionsModal: ModalComponent;

  constructor(
    private route: ActivatedRoute,
    private resourceService: ResourceService,
    private titleService: Title,
    private competenciesSelectService: CompetenciesSelectService,
    private store: Store,
    private router: Router,
    private alertService: AlertService,
    private districtSearchService: DistrictSearchService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.userIsDistrictOperator = checkIfDistrictOperator(this.user);
    this.userIsE2LOperator = checkIfE2LOperator(this.user);
  }

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      this.resourceSlug = url['resourceId'];
    });
    if (!this.competenciesToAdd) {
      this.competenciesToAdd = [];
    }
    if (!this.resourceCompetencies) {
      this.resourceCompetencies = [];
    }
    this.doneLoading = false;
    this.resourceService.getResourceBySlug(this.resourceSlug);
    if (
      this.user?.district &&
      !this.user.roles.includes(UserRole.E2L_EMPLOYEE || UserRole.ESUITE_ADMIN)
    ) {
      this.districtIds.push(this.user.district.id);
    }
    this.subs.push(
      this.resourceService.resourceError.subscribe((error) => {
        if (error) {
          this.resourceErrorMessage = error;
        }
      })
    );

    // get the resource from the resourceService
    this.subs.push(
      this.resourceService.currentResource.subscribe((currentResource) => {
        if (currentResource) {
          this.currentResource = currentResource;
          if (this.currentResource) {
            this.titleService.setTitle(
              `GroweLab: Resource - ${this.currentResource.title}`
            );
            if (this.currentResource.attachments) {
              this.attachments = this.currentResource.attachments;
            }
          }

          if (
            this.currentResource.your_access === 'own' ||
            this.currentResource.your_access === 'edit'
          ) {
            this.isOwnerOrEditor = true;
          }
          if (
            this.currentResource.your_access === 'own' ||
            this.currentResource.your_access === 'edit'
          ) {
            this.isOwnerOrEditor = true;
          }

          if (this.currentResource.lock_expires < new Date().getTime() / 1000) {
            this.resourceService.slugUnlock(this.resourceSlug);
          }

          if (
            this.currentResource.endorsements &&
            this.currentResource.endorsements.length > 0
          ) {
            this.checkForEndorsements();
          }
        } else {
          this.currentResource = null;
        }
        this.doneLoading = true;
      })
    );

    // locked status
    this.subs.push(
      this.resourceService.isLocked.subscribe((isLockedData) => {
        if (this.user && this.currentResource) {
          this.currentResource.lock_expires = isLockedData.lockExpires;
          this.currentResource.lock_user_id = isLockedData.lockUserId;
        }
      })
    );

    // published status
    this.subs.push(
      this.resourceService.isPublished.subscribe((isPublished) => {
        this.isPublished = isPublished;
      })
    );

    const leveledCompetencyFromCompetencyDTO = (
      item: CompetencyDTO
    ): LeveledCompetencyDTO => ({
      id: item.id,
      name: item.title,
      level1: false,
      level2: false,
      level3: false,
      level4: false,
      levelsFormatted: '',
      can_add: false,
      can_delete: false,
    });

    this.subs.push(
      this.competenciesSelectService.competencyLeveledResources.subscribe(
        (competency: CompetencyLeveledResourcesDto) => {
          this.invalidSelection = false;
          this.selectionNotAllowed = false;
          this.selectionAlreadyAdded = false;

          // make sure has permissions to add competency
          if (competency.can_add) {
            // make sure competency has not been added yet
            let didFind = this.competenciesToAdd?.find(
              (eachCompetency) =>
                eachCompetency.id === this.currentCompetency.id
            );
            if (!didFind) {
              didFind = this.resourceCompetencies?.find(
                (eachCompetency) =>
                  eachCompetency.id === this.currentCompetency.id
              );
            }
            if (!didFind) {
              const newComp = leveledCompetencyFromCompetencyDTO(
                this.currentCompetency
              );
              newComp.can_add = true;
              newComp.can_delete = true;
              this.competenciesToAdd?.push(newComp);
            } else {
              this.invalidSelection = true;
              this.selectionAlreadyAdded = true;
            }
          } else {
            this.invalidSelection = true;
            this.selectionNotAllowed = true;
          }
        }
      )
    );
  }

  toggleEditMode() {
    // toggling to view mode
    if (this.editMode) {
      if (this.currentResource?.lock_user_id === this.user.id) {
        this.resourceService.slugUnlock(this.resourceSlug);
      }
      this.resourceService.getResourceBySlug(this.resourceSlug);
      this.competenciesToAdd = [];
      this.editMode = !this.editMode;
    } else if (this.currentResource && this.user) {
      // toggling to edit mode
      if (
        !this.isLocked &&
        (this.currentResource.lock_user_id === this.user.id ||
          this.currentResource.lock_user_id === null)
      ) {
        this.resourceService.slugLock(this.resourceSlug);
      } else {
        this.isLocked = true;
      }

      this.editMode = !this.editMode;
      // sets the resource id so user can upload attachments
      setTimeout(() => {
        if (this.currentResource && this.uploadComponent) {
          (
            this.uploadComponent.fileService as ResourceFileService
          ).setResourceId(this.currentResource.id);
        }
      }, 500);
    }
  }

  saveTitle() {
    if (this.currentResource) {
      this.resourceService.updateTitle(this.currentResource.title).subscribe();
    }
  }

  saveType() {
    if (this.currentResource) {
      this.resourceService.updateType(this.currentResource.type).subscribe();
    }
  }

  updateResourceEndorsement(endorsed: boolean) {
    if (this.currentResource) {
      this.resourceService
        .updateResourceEndorsement(this.currentResource.id, endorsed)
        .subscribe((endorsement) => {
          if (
            this.currentResource &&
            endorsement &&
            this.currentResource.endorsements
          ) {
            if (endorsed) {
              this.currentResource.endorsements.push(endorsement);
              this.checkForEndorsements();
            } else {
              this.currentResource.endorsements =
                this.currentResource.endorsements.filter(
                  (item) => item.id !== endorsement.id
                );
              this.checkForEndorsements();
            }
          }
        });
    }
  }

  checkForEndorsements() {
    if (this.currentResource?.endorsements) {
      this.endorsedByE2L = this.currentResource.endorsements.some(
        (endorsement) => endorsement.district_id === 2
      );
      this.endorsedByE2LModel = this.endorsedByE2L;

      if (this.userIsE2LOperator) {
        // For e2L user, check if any other districts have endorsed the resource
        const districtEndorsements = this.currentResource.endorsements.filter(
          (endorsement) => endorsement.district_id !== 2
        );
        // Get the count of districts that have endorsed
        this.districtEndorsementCount = districtEndorsements.length;
        // Show district-approved icon if more than 0
        this.endorsedByDistrict = this.districtEndorsementCount > 0;

        // Get the names of all the districts
        districtEndorsements.forEach((endorsement) =>
          this.districtSearchService
            .getDistrict(endorsement.district_id)
            .subscribe((district) => {
              if (
                district &&
                !this.districtsWhoHaveEndorsed.includes(district.title)
              ) {
                this.districtsWhoHaveEndorsed.push(district.title);
              }
            })
        );
      } else {
        // For district users, check if their district has endorsed the resource
        this.endorsedByDistrict = this.currentResource.endorsements.some(
          (endorsement) => endorsement.district_id === this.user.district?.id
        );
        this.endorsedByDistrictModel = this.endorsedByDistrict;
      }
    }
  }

  saveDescription() {
    if (this.currentResource && this.currentResource.description.length > 0) {
      this.resourceService
        .updateDescription(this.currentResource.description)
        .subscribe();
    }
  }

  updateCompetenciesToAdd(competency: CompetencyDTO | null) {
    if (competency) {
      this.currentCompetency = competency;
      this.competenciesSelectService
        .getLeveledResourcesOnCompetency(competency.id)
        .subscribe();
    }
  }

  refreshCompetenciesToAdd(competencies: LeveledCompetencyDTO[]) {
    this.competenciesToAdd = competencies;
  }

  refreshResourceCompetencies(competencies: LeveledCompetencyDTO[]) {
    this.resourceCompetencies = competencies;
  }

  updateAttachments(file: FileDTO) {
    this.attachments.push(file);
    this.fileListComponent.fileListUpdate();
  }

  deleteAttachment(file: FileDTO) {
    this.attachments.forEach((attachment, index) => {
      if (attachment.id === file.id) {
        this.attachments.splice(index, 1);
      }
    });
  }

  deleteResource(resourceId: number) {
    this.resourceService.deleteResource(resourceId).subscribe(() => {
      this.router.navigate(['resources']);
    });
  }

  shareSettings() {
    this.permissionsModal.close();
    this.alertService.showAlert('Your resource has been published');
    this.editMode = false;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
