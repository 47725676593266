import {
  BooleanNumber,
  booleanNumberFromBoolean,
} from 'src/app/common/utilities/enums/boolean-number.enum';

import {
  Field,
  FormCreateDTO,
  FormLearnerBehaviorCreateDTO,
  FormStatus,
  FormType,
  ObservationFormSubmission,
  ObservationFormSubmissionRawData,
} from '../../dtos/forms.dto';

export interface FormDefinitionPayload {
  id?: number;
  share?: {
    include?: number[];
    exclude?: number[];
  };
  title: string;
  district_id?: number;
  description: string;
  fields: Field[];
  status: FormStatus;
  type: FormType;
  is_template: BooleanNumber;
  image: string;
}

export const formPayloadFromDTO = (
  form: FormCreateDTO
): FormDefinitionPayload => {
  const include =
    form.districtsSharedWith && form.isTemplate ? form.districtsSharedWith : [];
  const exclude =
    form.districtsSharedExclusion && form.isTemplate
      ? form.districtsSharedExclusion
      : [];
  return {
    share:
      include.length > 0 || exclude.length > 0
        ? {
            include,
            exclude,
          }
        : undefined,
    title: form.title,
    description: form.description,
    fields: form.fields,
    district_id: form.districtId || undefined,
    status: form.status,
    type: form.type,
    is_template: booleanNumberFromBoolean(form.isTemplate),
    id: form.id || undefined,
    image: form.image ? form.image : '',
  };
};

export interface FormSubmissionPayload {
  id?: number;
  observation_form_id: number;
  egrowe_coachlog_id?: number;
  submitter_district_id: number;
  submitter_user_id: number;
  submission_data: string;
  observed_user_id: number;
}

export const formSubmissionPayloadFromRawDataDTO = (
  formSubmissionData: ObservationFormSubmissionRawData
): FormSubmissionPayload => ({
  observation_form_id: formSubmissionData.formId,
  egrowe_coachlog_id: formSubmissionData.coachingLogId,
  submitter_district_id: formSubmissionData.submitterUser.district
    ?.id as number,
  submitter_user_id: formSubmissionData.submitterUser.id,
  submission_data: JSON.stringify(formSubmissionData.submissionData),
  observed_user_id: formSubmissionData.observedUserId as number,
});

export const formSubmissionPayloadFromDTO = (
  formSubmissionData: ObservationFormSubmission
): FormSubmissionPayload => ({
  id: formSubmissionData.id,
  observation_form_id: formSubmissionData.formId,
  egrowe_coachlog_id: formSubmissionData.coachingLogId,
  submitter_district_id: formSubmissionData.submitterDistrictId,
  submitter_user_id: formSubmissionData.submitterUserId,
  submission_data: JSON.stringify(formSubmissionData.submissionData),
  observed_user_id: formSubmissionData.observedUserId as number,
});

export interface FormLearnerBehaviorCreatePayload {
  egrowe_rubric_id: number;
  assessment_type: 'coach:learner_behavior';
  egrowe_indicator_id: number;
  assessee_user_id: number;
  egrowe_coachlog_id: number;
}

export const formLearnerBehaviorCreatePayloadFromDTO = (
  formLearnerBehavior: FormLearnerBehaviorCreateDTO
): FormLearnerBehaviorCreatePayload => ({
  egrowe_rubric_id: formLearnerBehavior.rubricId,
  assessment_type: 'coach:learner_behavior',
  egrowe_coachlog_id: formLearnerBehavior.coachlogId,
  egrowe_indicator_id: formLearnerBehavior.indicatorId,
  assessee_user_id: formLearnerBehavior.assesseeUserId,
});
