<div class="container container-main">
  <app-general-header
    [title]="
      ((selectedSchool && selectedSchool.title) ||
        (selectedDistrict && selectedDistrict.title) ||
        'District') + ' Reporting'
    " />
  <div class="card border-0 p-3 mb-3">
    <app-reporting-subnav></app-reporting-subnav>
    <div class="card-body">
      <h2 class="fw-bolder text-center mb-4">Reporting Dashboard</h2>
      <p class="mb-0">
        This reporting dashboard is meant to give leaders a high-level view of
        your district's progress. The visuals on this screen will help you
        quickly see if you are on track or off track.
        <!-- Commented out until this button is actually added to each report -->
        <!-- Hovering over the information icon ( <i class="bi bi-info-circle text-secondary"></i> ) on
        the different reports will give you more insights on how you can dig
        deeper and take action. -->
      </p>
    </div>
  </div>
  <div class="card border-0 mb-3">
    <div class="card-body">
      <app-report-filter-bar
        [canFilterByDistrict]="canFilterByDistrict"
        [reportName]="'report_dashboard'" />
    </div>
  </div>

  <ng-container *ngIf="filtersSet">
    <div id="quick-stats-row" class="row mb-3">
      <app-card-leader-glance
        [dashboardType]="'reporting'"
        [sessionParams]="yearToDateSessionParams"
        [obvservationParams]="yearToDateObservationParams"
        [strandBadgeParams]="yearToDateStrandBadgeParams"
        [microcredentialParams]="yearToDateMicrocredentialParams"
        [giParams]="yearToDateGIParams"
        [celebrationParams]="yearToDateCelebrationParams"
        [coachedParams]="peopleCoachedParams"
        [campusParams]="yearToDateCampusParams"
        [schoolYearId]="schoolYearId"
        [otherSupportSessionsParams]="otherSupportSessionsParams"
        [user]="user" />
    </div>

    <div id="kpi-row" class="row">
      <div class="col-md-4 mb-3">
        <div id="sessions-kpi" class="card border-0 text-center p-3">
          <ng-container
            *ngIf="
              !isMultiDistrict &&
              !isLoadingGoals &&
              sessionsPerCoacheeGoal &&
              sessionsKpiLoaded
            ">
            <div
              class="tracking-pill"
              [ngClass]="sessionsKpiOnTrack ? 'on-track' : 'off-track'">
              <ng-container *ngIf="sessionsKpiOnTrack; else offTrack">
                On Track
              </ng-container>
              <ng-template #offTrack> Off Track </ng-template>
            </div>
          </ng-container>
          <app-kpi-controller
            [textColor]="'level-4'"
            [primaryRequestParams]="{
              dataset: 'fct_coaching_session',
              measure: { type: 'count_distinct', field: 'log_id' },
              filters: filtersWithSessionType
            }"
            [secondaryRequestParams]="{
              dataset: 'fct_coaching_session',
              measure: { type: 'count_distinct', field: 'user_id' },
              filters: filtersWithSessionType,
            }"
            (outputValue)="setSessionsKpiValue($event)" />
          <ng-container *ngIf="!isLoadingGoals && sessionsPerCoacheeGoal">
            <div class="fs-5 fw-800 mb-1">
              {{ sessionsPerCoacheeGoal.title }}
            </div>
            <span *ngIf="!isMultiDistrict" class="small text-body-secondary">
              Goal: {{ sessionsPerCoacheeGoal.value }}
              {{ sessionsPerCoacheeGoal.description }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-4 mb-3">
        <div id="badges-kpi" class="card border-0 text-center p-3">
          <ng-container
            *ngIf="
              !isMultiDistrict &&
              !isLoadingGoals &&
              badgesPerCoacheePerSessionGoal &&
              badgesPerCoacheeLoaded
            ">
            <div
              class="tracking-pill"
              [ngClass]="badgesPerCoacheeOnTrack ? 'on-track' : 'off-track'">
              <ng-container *ngIf="badgesPerCoacheeOnTrack; else offTrack">
                On Track
              </ng-container>
              <ng-template #offTrack> Off Track </ng-template>
            </div>
          </ng-container>
          <app-kpi-controller
            [textColor]="'primary'"
            [primaryRequestParams]="{
              dataset: 'fct_user_badge',
              measure: { type: 'count', field: '' },
              filters: defaultFilters
            }"
            [secondaryRequestParams]="{
              dataset: 'fct_coaching_session',
              measure: { type: 'count', field: '' },
              filters: filtersWithSessionType
            }"
            (outputValue)="setBadgesPerCoacheeValue($event)" />
          <ng-container
            *ngIf="!isLoadingGoals && badgesPerCoacheePerSessionGoal">
            <div class="fs-5 fw-800 mb-1">
              {{ badgesPerCoacheePerSessionGoal.title }}
            </div>
            <span *ngIf="!isMultiDistrict" class="small text-body-secondary">
              Goal: {{ badgesPerCoacheePerSessionGoal.value }}
              {{ badgesPerCoacheePerSessionGoal.description }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-4 mb-3">
        <div id="celebrations-kpi" class="card border-0 text-center p-3">
          <ng-container
            *ngIf="
              !isMultiDistrict &&
              !isLoadingGoals &&
              celebrationsPerUserGoal &&
              celebrationsPerUserLoaded
            ">
            <div
              class="tracking-pill"
              [ngClass]="celebrationsPerUserOnTrack ? 'on-track' : 'off-track'">
              <ng-container *ngIf="celebrationsPerUserOnTrack; else offTrack">
                On Track
              </ng-container>
              <ng-template #offTrack> Off Track </ng-template>
            </div>
          </ng-container>
          <app-kpi-controller
            [textColor]="'level-2'"
            [primaryRequestParams]="{
              dataset: 'fct_user_celebration',
              measure: { type: 'count', field: '' },
              filters: defaultFilters
            }"
            [secondaryRequestParams]="{
              dataset: 'fct_user_celebration',
              measure: { type: 'count_distinct', field: 'user_id' },
              filters: defaultFilters
            }"
            (outputValue)="setCelebrationsPerUserValue($event)" />
          <ng-container *ngIf="!isLoadingGoals && celebrationsPerUserGoal">
            <div class="fs-5 fw-800 mb-1">
              {{ celebrationsPerUserGoal.title }}
            </div>
            <span *ngIf="!isMultiDistrict" class="small text-body-secondary">
              Goal: {{ celebrationsPerUserGoal.value }}
              {{ celebrationsPerUserGoal.description }}
            </span>
          </ng-container>
        </div>
      </div>
    </div>
    <div id="report-row" class="row">
      <div class="col-md-6 mb-3">
        <div id="bpp" class="card border-0">
          <ng-container
            *ngIf="
              !isMultiDistrict &&
              !isLoadingGoals &&
              sessionsPerCoacheeGoal &&
              sessionsPerCoacheeLoaded
            ">
            <div
              class="tracking-pill"
              [ngClass]="sessionsPerCoacheeOnTrack ? 'on-track' : 'off-track'">
              <ng-container *ngIf="sessionsPerCoacheeOnTrack; else offTrack">
                On Track
              </ng-container>
              <ng-template #offTrack> Off Track </ng-template>
            </div>
          </ng-container>
          <div id="coaching-conversations-chart" class="card-body">
            <div class="mb-3">
              <ng-container *ngIf="!isLoadingGoals && sessionsPerCoacheeGoal">
                <div class="fs-5 fw-800 mb-1">
                  {{ sessionsPerCoacheeGoal.title }}
                </div>
                <span
                  *ngIf="!isMultiDistrict"
                  class="small text-body-secondary">
                  Goal: {{ sessionsPerCoacheeGoal.value }}
                  {{ sessionsPerCoacheeGoal.description }}
                </span>
              </ng-container>
            </div>
            <app-report-controller
              #sessionsPerCoacheeChart
              *ngIf="filtersSet"
              chartId="coaching-conversations-chart"
              [chartType]="[chartTypes.Area, chartTypes.Line]"
              [chartColor]="['#ffafd7', '#d95195']"
              yAxisLabel="Session Count"
              (apiResponseEvent)="getTrackData($event)"
              [isMultiDistrict]="isMultiDistrict"
              [gridDimensions]="{
                top: '10',
                left: '30',
                right: '30',
                bottom: '25',
              }"
              [goal]="
                !isMultiDistrict ? sessionsPerCoacheeGoal?.value || null : null
              "
              [legendOptions]="{
                show: true,
                bottom: 0,
                icon: 'circle',
                formatter: '{name}',
                textStyle: {
                  fontFamily: 'greycliff-cf'
                }
              }"
              [requestParams]="{
                dataset: 'fct_coaching_session',
                measure: {
                  type: 'rolling_sum',
                  field: 'log_id',
                },
                filters: filtersWithSessionType
              }" />
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <div id="bpp" class="card border-0">
          <ng-container
            *ngIf="
              !isMultiDistrict &&
              !isLoadingGoals &&
              observationsPerCoacheeGoal &&
              observationsPerCoacheeLoaded
            ">
            <div
              class="tracking-pill"
              [ngClass]="
                observationsPerCoacheeOnTrack ? 'on-track' : 'off-track'
              ">
              <ng-container
                *ngIf="observationsPerCoacheeOnTrack; else offTrack">
                On Track
              </ng-container>
              <ng-template #offTrack> Off Track </ng-template>
            </div>
          </ng-container>
          <div id="classroom-visits-chart" class="card-body">
            <div class="mb-3">
              <ng-container
                *ngIf="!isLoadingGoals && observationsPerCoacheeGoal">
                <div class="fs-5 fw-800 mb-1">
                  {{ observationsPerCoacheeGoal.title }}
                </div>
                <span
                  *ngIf="!isMultiDistrict"
                  class="small text-body-secondary">
                  Goal: {{ observationsPerCoacheeGoal.value }}
                  {{ observationsPerCoacheeGoal.description }}
                </span>
              </ng-container>
            </div>
            <app-report-controller
              *ngIf="filtersSet"
              chartId="classroom-visits-chart"
              yAxisLabel="Observation Count"
              (apiResponseEvent)="getTrackData($event)"
              [chartType]="[chartTypes.Area, chartTypes.Line]"
              [chartColor]="['#9cdcff', '#267dad']"
              [isMultiDistrict]="isMultiDistrict"
              [goal]="
                !isMultiDistrict
                  ? observationsPerCoacheeGoal?.value || null
                  : null
              "
              [gridDimensions]="{
                top: '10',
                left: '30',
                right: '30',
                bottom: '25',
              }"
              [legendOptions]="{
                show: true,
                bottom: 0,
                icon: 'circle',
                formatter: '{name}',
                textStyle: {
                  fontFamily: 'greycliff-cf'
                }
              }"
              [requestParams]="{
                dataset: 'fct_form_submission',
                measure: {
                  type: 'rolling_sum',
                  field: 'log_id',
                },
                filters: defaultFilters
              }" />
          </div>
        </div>
      </div>
    </div>
    <div id="alternate-report-row" class="row">
      <div class="col-md-6 mb-3">
        <div id="bpp" class="card border-0">
          <ng-container
            *ngIf="
              !isMultiDistrict &&
              !isLoadingGoals &&
              skillAttainmentGoal &&
              skillsAttainedLoaded
            ">
            <div
              class="tracking-pill"
              [ngClass]="skillsAttainedOnTrack ? 'on-track' : 'off-track'">
              <ng-container *ngIf="skillsAttainedOnTrack; else offTrack">
                On Track
              </ng-container>
              <ng-template #offTrack> Off Track </ng-template>
            </div>
          </ng-container>
          <div id="skill-attainment-chart" class="card-body">
            <ng-container *ngIf="skillAttainmentGoal">
              <div class="fs-5 fw-800 mb-1 tracking-pill-padding">
                {{ skillAttainmentGoal.title }}:
                {{ skillAttainmentGoal.description }}
              </div>
              <span *ngIf="!isMultiDistrict" class="small text-body-secondary">
                Goal: {{ skillAttainmentGoal.value }}
                {{ skillAttainmentGoal.description }}
              </span>
            </ng-container>
            <app-report-controller
              *ngIf="filtersSet"
              chartId="badges-per-coachee-chart"
              (apiResponseEvent)="getTrackData($event)"
              [chartType]="[chartTypes.Area, chartTypes.Line]"
              [chartColor]="['#dfd0f3', '#27004b']"
              [isMultiDistrict]="isMultiDistrict"
              yAxisLabel="Badges"
              [gridDimensions]="{
                top: '10',
                left: '30',
                right: '30',
                bottom: '25',
              }"
              [goal]="
                !isMultiDistrict ? skillAttainmentGoal?.value || null : null
              "
              [legendOptions]="{
                show: true,
                bottom: 0,
                icon: 'circle',
                formatter: '{name}',
                textStyle: {
                  fontFamily: 'greycliff-cf'
                }
              }"
              [requestParams]="{
                dataset: 'fct_user_badge',
                measure: {
                  type: 'rolling_sum',
                  field: 'badge_id',
                },
                filters: defaultFilters,
              }" />
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3">
        <div class="card border-0">
          <div id="competency-proficiency-chart" class="card-body">
            <div class="fs-5 fw-800 mb-1">Badges Earned</div>
            <app-report-controller
              *ngIf="filtersSet"
              chartId="competency-proficiency-chart"
              [chartType]="[chartTypes.HorizontalBar]"
              [cardHeight]="'34rem'"
              yAxisLabel="Badges Earned"
              [chartColor]="['#d95195']"
              [requestParams]="{
                dataset: 'fct_user_badge',
                dimension: 'standard_name',
                measure: {
                  type: 'count',
                  field: '*',
                },
                filters: defaultFilters
              }" />
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <div class="card border-0">
          <div id="session-attendance-chart" class="card-body">
            <div class="fs-5 fw-800 mb-1">Session Attendance</div>
            <app-report-controller
              *ngIf="filtersSet"
              [cardHeight]="'50rem'"
              chartId="session-attendance-chart"
              [chartType]="[chartTypes.Area]"
              yAxisLabel="Attendance Percentage"
              [yNameGap]="35"
              [chartColor]="['#27004b']"
              [percentage]="true"
              [requestParams]="{
                dataset: 'fct_coaching_session',
                measure: {
                  type: 'average',
                  field: 'is_present',
                  },
                filters: defaultFilters
              }" />
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <div class="card border-0">
          <div
            id="time-frequency-chart"
            class="card-body"
            style="height: 56rem">
            <div class="fs-5 fw-800 mb-1">Time & Frequency</div>
            <app-card-report-time-frequency
              [defaultFilters]="defaultFilters"
              *ngIf="filtersSet"></app-card-report-time-frequency>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <div class="card border-0">
          <div id="microcredentials-chart" class="card-body">
            <div class="fs-5 fw-800 mb-1">Microcredentials</div>
            <app-report-controller
              *ngIf="filtersSet"
              chartId="microcredentials-chart"
              [xNameGap]="23"
              [yNameGap]="25"
              [chartColor]="['#267dad']"
              [gridDimensions]="{
                top: '10px',
                left: '27px',
                right: '15px',
                bottom: '25px',
              }"
              [chartType]="[chartTypes.HorizontalBar]"
              [requestParams]="{
                dataset: 'fct_user_badge',
                dimension: 'user_id',
                measure: {
                  type: 'count_distinct_percent',
                  field: 'badge_id',
                },
                filters: filtersWithCompetency
              }"
              [percentage]="true"
              yAxisLabel="Percentage of coachees that earned microcredentials"
              xAxisLabel="Number of microcredentials earned" />
          </div>
        </div>
      </div>
    </div>
    <div
      id="student-outcome-row"
      class="row"
      *ngIf="featureFlagService.isEnabled('StudentOutcomes')">
      <div class="col-md-6 mb-3">
        <div class="card border-0">
          <div id="map-overall-test-results-chart" class="card-body">
            <div class="fs-5 fw-800 mb-1">MAP Overall Test Results</div>
            <app-report-controller
              *ngIf="filtersSet"
              chartId="map-overall-test-results-chart"
              [yNameGap]="38"
              [chartType]="[chartTypes.Bar]"
              [requestParams]="{
                dimension: 'score_group_name',
                dataset: 'fct_test_result',
                measure: {
                  type: 'count',
                  field: '*',
                },
                filters: defaultFilters
              }"
              xAxisLabel="Score Group"
              yAxisLabel="Student Count" />
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <div class="card border-0">
          <div id="map-students-meeting-target-chart" class="card-body">
            <div class="fs-5 fw-800 mb-1">MAP Students Meeting Target</div>
            <app-report-controller
              *ngIf="filtersSet"
              chartId="map-students-meeting-target-chart"
              [drilldownDimensions]="['teacher_name']"
              [chartType]="[chartTypes.HorizontalBar]"
              [percentage]="true"
              yAxisLabel="Percent Met Target"
              [requestParams]="{
                dimension: 'school_name',
                dataset: 'fct_test_result',
                measure: {
                  type: 'percent_true',
                  field: 'score_met_target',
                },
                filters: defaultFilters
              }" />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 text-muted small text-end">
      Data last refreshed at {{ lastUpdatedDate }}
    </div>

    <!-- Employee Support View -->

    <div
      id="e2l-report-row"
      class="row bg-secondary rounded pt-2 mt-5"
      *ngIf="isE2L">
      <h3
        (click)="this.isCollapsed = !this.isCollapsed"
        [style.cursor]="'pointer'"
        class="fs-6 w-100 fw-800 text-body-secondary text-center">
        e2l-Employee Support View
        <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill ml-5"></i>
      </h3>
      <div
        class="row m-auto"
        #collapse="ngbCollapse"
        [(ngbCollapse)]="isCollapsed">
        <div class="col-md-4 mb-3">
          <div class="card border-0">
            <div
              id="coaching-sessions-count-chart"
              class="card-body"
              style="height: 30rem">
              <div class="fs-5 fw-800 mb-1">Coaching Sessions</div>
              <app-report-controller
                *ngIf="filtersSet"
                chartId="coaching-sessions-count-chart"
                [cardHeight]="'25rem'"
                [chartType]="[chartTypes.Bar]"
                yAxisLabel="Session Count"
                [drilldownDimensions]="['session_date']"
                [requestParams]="{
                  dataset: 'fct_coaching_session',
                  measure: {
                    type: 'count_distinct',
                    field: 'log_id',
                  },
                  filters: filtersWithSessionType
                }" />
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <div class="card border-0">
            <div
              id="observations-count-chart"
              class="card-body"
              style="height: 30rem">
              <div class="fs-5 fw-800 mb-1">Observations</div>
              <app-report-controller
                *ngIf="filtersSet"
                chartId="observations-count-chart"
                [cardHeight]="'25rem'"
                [chartType]="[chartTypes.Bar]"
                yAxisLabel="Observation Count"
                [requestParams]="{
                  dataset: 'fct_form_submission',
                  measure: {
                    type: 'count',
                    field: '*',
                  },
                  filters: defaultFilters
                }" />
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <div class="card border-0">
            <div
              id="celebrations-count-chart"
              class="card-body"
              style="height: 30rem">
              <div class="fs-5 fw-800 mb-1">Celebrations</div>
              <app-report-controller
                *ngIf="filtersSet"
                chartId="celebrations-count-chart"
                [cardHeight]="'25rem'"
                [chartType]="[chartTypes.Bar]"
                [yNameGap]="22"
                yAxisLabel="Celebration Count"
                [requestParams]="{
                  dataset: 'fct_user_celebration',
                  measure: {
                    type: 'count',
                    field: '*',
                  },
                  filters: defaultFilters
                }" />
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <div class="card border-0">
            <div
              id="people-coached-count-chart"
              class="card-body"
              style="height: 30rem">
              <div class="fs-5 fw-800 mb-1">People Coached</div>
              <app-report-controller
                *ngIf="filtersSet"
                chartId="people-coached-count-chart"
                [cardHeight]="'25rem'"
                [chartType]="[chartTypes.Bar]"
                yAxisLabel="Learner Count"
                [yNameGap]="22"
                [drilldownDimensions]="['session_date']"
                [requestParams]="{
                  dataset: 'fct_coaching_session',
                  measure: {
                    type: 'count_distinct',
                    field: 'user_id',
                  },
                  filters: filtersWithSessionType
                }" />
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <div class="card border-0">
            <div
              id="people-observed-count-chart"
              class="card-body"
              style="height: 30rem">
              <div class="fs-5 fw-800 mb-1">People Observed</div>
              <app-report-controller
                *ngIf="filtersSet"
                chartId="people-observed-count-chart"
                [cardHeight]="'25rem'"
                [chartType]="[chartTypes.Bar]"
                yAxisLabel="Observation Count"
                [yNameGap]="22"
                [requestParams]="{
                  dataset: 'fct_form_submission',
                  measure: {
                    type: 'count_distinct',
                    field: 'user_id',
                  },
                  filters: defaultFilters
                }" />
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <div class="card border-0">
            <div
              id="people-celebrated-count-chart"
              class="card-body"
              style="height: 30rem">
              <div class="fs-5 fw-800 mb-1">People Celebrated</div>
              <app-report-controller
                *ngIf="filtersSet"
                chartId="people-celebrated-count-chart"
                [cardHeight]="'25rem'"
                [chartType]="[chartTypes.Bar]"
                yAxisLabel="Celebration Count"
                [requestParams]="{
                  dataset: 'fct_user_celebration',
                  measure: {
                    type: 'count_distinct',
                    field: 'user_id',
                  },
                  filters: defaultFilters
                }" />
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <div class="card border-0">
            <div
              id="badges-earned-count-chart"
              class="card-body"
              style="height: 30rem">
              <div class="fs-5 fw-800 mb-1">Badges Earned</div>
              <app-report-controller
                *ngIf="filtersSet"
                chartId="badges-earned-count-chart"
                [cardHeight]="'25rem'"
                [chartType]="[chartTypes.Bar]"
                yAxisLabel="Badge Count"
                [requestParams]="{
                  dataset: 'fct_user_badge',
                  measure: {
                    type: 'count',
                    field: '*',
                  },
                  filters: defaultFilters
                }" />
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <div class="card border-0">
            <div
              id="indicators-accessed-count-chart"
              class="card-body"
              style="height: 30rem">
              <div class="fs-5 fw-800 mb-1">Indicators Assessed</div>
              <app-report-controller
                *ngIf="filtersSet"
                chartId="indicators-accessed-count-chart"
                [cardHeight]="'25rem'"
                [yNameGap]="28"
                yAxisLabel="Indicator Count"
                [chartType]="[chartTypes.Bar]"
                [requestParams]="{
                  dataset: 'fct_indicator_assessment',
                  measure: {
                    type: 'count',
                    field: '*',
                  },
                  filters: defaultFilters
                }" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
