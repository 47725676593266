<div class="container">
  <ngb-alert
    class="copied-success"
    #copiedAlert
    *ngIf="copiedStatus"
    type="success"
    >Copied to Clipboard
  </ngb-alert>

  <div class="card border-0 p-3">
    <div class="card-body">
      <ng-container *ngIf="folder; else loadingTemplate">
        @if(folder.breadCrumb) {
        <app-resources-breadcrumb
          [folderOwner]="user"
          [breadcrumbs]="folder.breadCrumb" />
        } @else {
        <ul class="breadcrumb">
          <li>
            <i class="bi bi-book me-2"></i>

            <a routerLink="/resources/my">My Resources </a>
          </li>
          <li *ngIf="isCreatedByMe">Created By You</li>
        </ul>
        }

        <div class="folder-head d-flex justify-content-between mt-4 mb-5">
          <div class="folder-head">
            <div class="folder-title" *ngIf="!isCreatedByMe">
              {{ folder.title }}
            </div>
            <div class="folder-title" *ngIf="isCreatedByMe">Created by You</div>
            <div class="folder-description" *ngIf="folder.description">
              {{ folder.description }}
            </div>
          </div>
          <div class="folder-head-actions d-flex">
            <div
              role="button"
              title="share"
              aria-label="share"
              *ngIf="!isCreatedByMe"
              class="btn-folder-action p-1"
              (click)="copyToClipboard(folder.id)"
              ngbTooltip="Share Folder">
              <i class="bi bi-share"></i>
            </div>
            <div
              role="button"
              title="edit"
              aria-label="edit"
              *ngIf="!isCreatedByMe"
              class="btn-folder-action p-1 mx-2"
              (click)="editFolderModal.open()"
              ngbTooltip="Edit Folder">
              <i class="bi bi-pencil"></i>
            </div>
            <ng-container *ngIf="!isCreatedByMe && !hasChildren">
              <div
                aria-label="delete folder button"
                class="btn-folder-action p-1 btn-reset"
                ngbTooltip="Delete Folder"
                [autoClose]="'outside'"
                [ngbPopover]="deleteFolderTemplate"
                [popoverTitle]="'Delete Folder'"
                #popover="ngbPopover">
                <i class="bi bi-trash"></i>
              </div>
              <ng-template #deleteFolderTemplate>
                Are you sure you want to delete this Folder?
                <div class="text-end mt-2">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm px-3 m-1"
                    (click)="popover.close()">
                    No
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm px-3 m-1"
                    (click)="deleteFolder(); popover.close()">
                    Yes
                  </button>
                </div>
              </ng-template>
            </ng-container>
            <div
              title="delete"
              aria-label="delete"
              *ngIf="!isCreatedByMe && hasChildren"
              class="p-1 has-children btn-disabled">
              <i
                ngbTooltip="This folder contains content. Remove all resources and folders to enable this action."
                class="bi bi-trash"></i>
            </div>
          </div>
        </div>

        <div
          *ngIf="!isCreatedByMe && myChildFolders.length == 0"
          class="resource-header my-5 d-flex justify-content-right">
          <button class="btn btn-link p-1" (click)="createFolderModal.open()">
            <i class="bi bi-folder-plus me-2"></i>Create a Folder
          </button>
        </div>

        <ng-container *ngIf="!isCreatedByMe">
          <ng-container
            *ngIf="
              myStarredChildFolders.length > 0 || myChildFolders.length > 0
            ">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <div class="section-head">Folders</div>
              <div *ngIf="!isCreatedByMe">
                <button
                  class="btn btn-link p-1"
                  (click)="createFolderModal.open()">
                  <i class="bi bi-folder-plus me-2"></i>Create a New Folder
                </button>
              </div>
            </div>
            <ng-container *ngIf="myStarredChildFolders; else loading">
              <div cdkDropList class="folders">
                <div *ngFor="let folder of myStarredChildFolders">
                  <app-folder-item
                    [folder]="folder"
                    [multiFolders]="myStarredChildFolders.length > 1"
                    (folderClicked)="resetFolder()"
                    (copyClicked)="copyToClipboard($event)"
                    (starClicked)="
                      starSelect(
                        folder.id,
                        folder.usersFavorite,
                        myStarredChildFolders
                      )
                    "></app-folder-item>
                </div>
              </div>
              <div
                *ngIf="myStarredChildFolders.length >= 1"
                style="margin: 25px 0"></div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="myChildFolders; else loading">
            <div cdkDropList class="folders">
              <div *ngFor="let folder of myChildFolders">
                <app-folder-item
                  [folder]="folder"
                  [multiFolders]="myChildFolders.length > 1"
                  (folderClicked)="resetFolder()"
                  (copyClicked)="copyToClipboard($event)"
                  (starClicked)="
                    starSelect(folder.id, folder.usersFavorite, myChildFolders)
                  "></app-folder-item>
              </div>
            </div>
          </ng-container>
          <ng-template #loading>
            <div class="w-100 text-center" style="height: 60vh">
              <app-loader> </app-loader>
            </div>
          </ng-template>
        </ng-container>

        <div class="section-head mt-5 mb-3">Resources</div>
        <ng-container *ngIf="folderItems; else Loading">
          <ng-container *ngFor="let folderItem of folderItems">
            <ng-container *ngIf="folderItem.type !== 'course'">
              <app-resource-list-item
                [resourceListItem]="folderItem"
                [userDistrictId]="
                  user.district ? user.district.id : null
                "></app-resource-list-item>
            </ng-container>
            <ng-container *ngIf="folderItem.type == 'course'">
              <app-online-course [course]="folderItem"></app-online-course>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="
              (folder.numbResources && folder.numbResources > 0) ||
                folder.numbItems > 0;
              else noResults
            ">
            <div class="d-flex justify-content-center">
              <ngb-pagination
                *ngIf="folderListMeta && folderListMeta.totalCount > 10"
                [(page)]="folderListMeta.currentPage"
                [pageSize]="folderListMeta.perPage"
                [collectionSize]="folderListMeta.totalCount"
                (pageChange)="paginationChange()"
                [maxSize]="5"
                [ellipses]="true"></ngb-pagination>
            </div>
          </ng-container>
          <ng-template #noResults>
            <div class="no-content-message">
              No resources have been added to this folder.
              <div class="mt-4 fw-400">
                Visit
                <a routerLink="/resources">Discover Resources</a> to bookmark
                resources.
              </div>
              .
            </div>
          </ng-template>
        </ng-container>
        <ng-template #Loading>
          <div class="w-100 text-center" style="height: 60vh">
            <app-loader></app-loader>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #loadingTemplate>
        <div class="w-100 text-center" style="height: 60vh">
          <app-loader></app-loader>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<app-modal
  #editFolderModal
  [modalConfig]="{
    titleText: 'Edit Folder',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    windowClass: 'edit-folder-class',
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="editFolderModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div>
    <label class="form-label" for="edit-folder-title">Title</label>
    <input
      class="form-control"
      id="edit-folder-title"
      [(ngModel)]="tempTitle" />
    <div>
      <label class="form-label" for="edit-folder-description"
        >Description</label
      >
      <textarea
        class="form-control"
        id="edit-folder-description"
        [(ngModel)]="tempDescription"
        rows="4"
        placeholder="Enter a description for this folder"></textarea>
    </div>
  </div>
  <div footerContent>
    <button
      [disabled]="tempTitle.length < 1"
      class="btn btn-primary"
      (click)="editFolderModal.close()"
      (click)="saveTitleDescription()">
      Apply Change
    </button>
  </div>
</app-modal>

<app-modal
  #createFolderModal
  [modalConfig]="{
    titleText: 'Create a New Folder',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    windowClass: 'edit-folder-class',
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="createFolderModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <label for="create-folder-title" class="form-label">Title</label>
  <input
    class="form-control"
    id="create-folder-title"
    placeholder="Enter Folder Title"
    [(ngModel)]="newFolder.title"
    required />
  <label for="create-folder-description" class="form-label">Description</label>
  <textarea
    id="create-folder-description"
    class="form-control"
    [(ngModel)]="newFolder.description"
    rows="4"
    placeholder="Enter a description for this folder"></textarea>
  <div class="note mt-1">
    Note: This Folder will be located within the Current Folder.
  </div>
  <div footerContent>
    <div>
      <button
        [disabled]="newFolder.title.length < 1"
        class="btn btn-primary"
        (click)="createFolderModal.close()"
        (click)="createFolder()">
        Create Folder
      </button>
    </div>
  </div>
</app-modal>
