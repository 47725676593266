import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { PlanSearchParams } from 'src/app/private/pages/plans-page/plans-page-helpers';
import { CommentModelDTO } from '../../components/evidence-comments/evidence-comments.dto';
import {
  CopyPlanPayload,
  UpdatePlanSharingPayload,
} from '../../dtos/plans.dto';
import {
  evidenceAttachmentDTOFromAPIResponse,
  fileDTOFromEvidenceAttachment,
} from '../../helpers/translators/evidence.translators';
import {
  CreateActionItemPayload,
  CreateEditPhasePayload,
  CreateEditPlanPayload,
  CreateStatusPlanPayload,
  EmailPreviewPayload,
  NewDeliverablePayload,
} from '../../types/payloads/plan.payload';
import {
  EditActionItemPayload,
  EditDeliverablePayload,
} from '../../types/payloads/plan.payloads';
import { EvidenceAttachmentAPIResponse } from '../../types/responses/coaching-log.responses';
import {
  PlanDashboardItemAPIResponse,
  PlanDetailsItemAPIResponse,
  StatusType,
} from '../../types/responses/plan.responses';
import { PlansPusherService } from './plans-pusher.service';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  constructor(
    private apiService: APICoreService,
    public plansPusherService: PlansPusherService
  ) {}

  connectToPusherChannel(planId: number) {
    this.plansPusherService.addChannel(`plan-${planId}`);
  }

  getPlans(payload: PlanSearchParams) {
    return this.apiService.getRequest('growelab/implementation-plan', payload);
  }

  getPlan(planId: number) {
    return this.apiService.getRequest(`growelab/implementation-plan/${planId}`);
  }

  updatePlan(planId: number, payload: CreateEditPlanPayload) {
    return this.apiService.putRequest(
      `growelab/implementation-plan/${planId}`,
      payload
    );
  }

  deletePlan(planId: number) {
    return this.apiService.deleteRequest(
      `growelab/implementation-plan/${planId}`
    );
  }

  getPlanDetails(
    planId: number
  ): Observable<{ item: PlanDetailsItemAPIResponse }> {
    this.connectToPusherChannel(planId);
    return this.apiService.getRequest(
      `growelab/implementation-plan/${planId}/details`
    );
  }

  getPlanDashboard(
    planId: number
  ): Observable<{ item: PlanDashboardItemAPIResponse }> {
    return this.apiService.getRequest(
      `growelab/implementation-plan/${planId}/dashboard`
    );
  }

  createPlan(payload: CreateEditPlanPayload) {
    return this.apiService.postRequest('growelab/implementation-plan', payload);
  }

  copyPlan(planId: number, payload: CopyPlanPayload) {
    return this.apiService.postRequest(
      `growelab/implementation-plan/${planId}/copy`,
      payload
    );
  }

  // PHASES

  createPhase(planId: number, payload: CreateEditPhasePayload) {
    return this.apiService.postRequest(
      `growelab/implementation-plan/${planId}/phase`,
      payload
    );
  }

  editPhase(phaseId: number, payload: CreateEditPhasePayload) {
    return this.apiService.putRequest(
      `growelab/implementation-plan/phase/${phaseId}`,
      payload
    );
  }

  deletePhase(phaseId: number) {
    return this.apiService.deleteRequest(
      `growelab/implementation-plan/phase/${phaseId}`
    );
  }

  updatePhaseStatus(phaseId: number, status: StatusType) {
    return this.apiService.putRequest(
      `growelab/implementation-plan/phase/${phaseId}`,
      { status }
    );
  }

  addPhaseAssignee(phaseId: number, userId: number) {
    return this.apiService.postRequest(
      `growelab/implementation-plan/phase/${phaseId}/assignee`,
      { user_id: userId }
    );
  }

  deletePhaseAssignee(phaseId: number, userId: number) {
    return this.apiService.deleteRequest(
      `growelab/implementation-plan/phase/${phaseId}/assignee/${userId}`
    );
  }

  // DELIVERABLES

  createDeliverable(
    phaseId: number,
    deliverablePayload: NewDeliverablePayload
  ) {
    return this.apiService.postRequest(
      `growelab/implementation-plan/phase/${phaseId}/deliverable`,
      deliverablePayload
    );
  }

  updateDeliverable(id: number, deliverablePayload: EditDeliverablePayload) {
    return this.apiService.putRequest(
      `growelab/implementation-plan/deliverable/${id}`,
      deliverablePayload
    );
  }

  deleteDeliverable(id: number) {
    return this.apiService.deleteRequest(
      `growelab/implementation-plan/deliverable/${id}`
    );
  }

  updateDeliverableStatus(deliverableId: number, status: StatusType) {
    return this.apiService.putRequest(
      `growelab/implementation-plan/deliverable/${deliverableId}`,
      { status }
    );
  }

  addDeliverableAssignee(delId: number, userId: number) {
    return this.apiService.postRequest(
      `growelab/implementation-plan/deliverable/${delId}/assignee`,
      { user_id: userId }
    );
  }

  deleteDeliverableAssignee(deliverableId: number, userId: number) {
    return this.apiService.deleteRequest(
      `growelab/implementation-plan/deliverable/${deliverableId}/assignee/${userId}`
    );
  }

  getDeliverableAttachments(deliverableId: number) {
    return this.apiService
      .getRequest(
        `growelab/implementation-plan/deliverable/${deliverableId}/attachments`
      )
      .pipe(
        map((res) =>
          res.items.map((item: EvidenceAttachmentAPIResponse) =>
            fileDTOFromEvidenceAttachment(
              evidenceAttachmentDTOFromAPIResponse(item)
            )
          )
        )
      );
  }

  // ACTION ITEMS

  updateActionItemStatus(actionItemId: number, status: StatusType) {
    return this.apiService.putRequest(
      `growelab/implementation-plan/action-item/${actionItemId}`,
      { status }
    );
  }

  createActionItem(delId: number, payload: CreateActionItemPayload) {
    return this.apiService.postRequest(
      `growelab/implementation-plan/deliverable/${delId}/action-item`,
      payload
    );
  }

  updateActionItem(id: number, payload: EditActionItemPayload) {
    return this.apiService.putRequest(
      `growelab/implementation-plan/action-item/${id}`,
      payload
    );
  }

  deleteActionItem(id: number) {
    return this.apiService.deleteRequest(
      `growelab/implementation-plan/action-item/${id}`
    );
  }

  addActionItemAssignee(actionId: number, userId: number) {
    return this.apiService.postRequest(
      `growelab/implementation-plan/action-item/${actionId}/assignee`,
      { user_id: userId }
    );
  }

  deleteActionItemAssignee(actionItemId: number, userId: number) {
    return this.apiService.deleteRequest(
      `growelab/implementation-plan/action-item/${actionItemId}/assignee/${userId}`
    );
  }

  getActionItemAttachments(actionItemId: number) {
    return this.apiService
      .getRequest(
        `growelab/implementation-plan/action-item/${actionItemId}/attachments`
      )
      .pipe(
        map((res) =>
          res.items.map((item: EvidenceAttachmentAPIResponse) =>
            fileDTOFromEvidenceAttachment(
              evidenceAttachmentDTOFromAPIResponse(item)
            )
          )
        )
      );
  }

  // COMMENTS

  getComments(refTable: string, refTableId: number) {
    return this.apiService.getRequest('comments', {
      ref_table: refTable,
      ref_table_id: refTableId,
    });
  }

  saveComment(newComment: CommentModelDTO) {
    return this.apiService.postRequest('comments', newComment);
  }

  deleteComment(comment: CommentModelDTO) {
    return this.apiService.deleteRequest(`comments/${comment.id}`);
  }

  // SHARING

  getPlanSharing(planId: number) {
    return this.apiService.getRequest(
      `growelab/implementation-plan/${planId}/sharing`
    );
  }

  updatePlanSharing(planId: number, payload: UpdatePlanSharingPayload) {
    return this.apiService.putRequest(
      `growelab/implementation-plan/${planId}/sharing`,
      payload
    );
  }

  updateSharingLogo(planId: number, file: File | null) {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    return this.apiService.postFormRequest(
      `growelab/implementation-plan/${planId}/sharing/logo`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  getSharingLogo(planId: number) {
    return this.apiService
      .getRequest(`growelab/implementation-plan/${planId}/sharing/logo`)
      .pipe(
        // eslint-disable-next-line consistent-return
        map((res) => {
          if (res.item) {
            return fileDTOFromEvidenceAttachment(
              evidenceAttachmentDTOFromAPIResponse(res.item)
            );
          }
        })
      );
  }

  getPlanStatusReports(planId: number, page = 1) {
    return this.apiService.getRequest(
      `growelab/implementation-plan/${planId}/emails?expand=user.profile&page=${page}`
    );
  }

  deletePlanStatusReport(reportId: number) {
    return this.apiService.deleteRequest(
      `growelab/implementation-plan/emails/${reportId}`
    );
  }

  previewEmail(planId: number, payload: EmailPreviewPayload) {
    return this.apiService.postRequest(
      `growelab/implementation-plan/${planId}/emails/preview`,
      payload
    );
  }

  createStatusReport(planId: number, payload: CreateStatusPlanPayload) {
    return this.apiService.postRequest(
      `growelab/implementation-plan/${planId}/emails`,
      payload
    );
  }
}
