<div class="card border border-secondary border-3 text-center p-3 h-100">
  <div class="card-body d-flex flex-column justify-content-center">
    <i
      ngbTooltip="Select resource"
      (click)="addItem()"
      class="top-icon fs-25 bi bi-plus-circle add-icon"></i>
    <div class="d-flex top-right-icons align-items-center">
      <img
        *ngIf="resource.e2lRecommended"
        ngbTooltip="e2L-Recommended"
        src="assets/e2l-logo.png"
        class="recommended-logo me-1"
        alt="e2L"
        role="image" />
      <i
        *ngIf="resource.districtApproved"
        ngbTooltip="District-Approved"
        class="bi bi-building-check approved-logo color-level-4 me-1"></i>
      <div
        ngbTooltip="Level {{ level }}"
        tooltipClass="no-wrap-tooltip"
        class="level"
        style="color: #fff"
        [ngClass]="{
          'bg-level-1': level == 1,
          'bg-level-2': level == 2,
          'bg-level-3': level == 3,
          'bg-level-4': level == 4
        }">
        {{ level }}
      </div>
    </div>
    <i class="bi bi-tools fs-35px"></i>
    <a
      target="blank"
      [href]="'resources/' + resource.slug"
      class="h5 fs-16 fw-bold title">
      {{ resource.title }}
      <i
        class="preview-icon bi bi-box-arrow-in-up-right"
        ngbTooltip="Preview"></i>
    </a>
    <p class="card-text fs-11" [innerHTML]="description"></p>
  </div>
</div>
