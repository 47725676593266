import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CoachingLogResourceService } from '../../../services/coaching-log/coaching-log-resource.service';
import {
  ModalComponent,
  ModalConfig,
} from '../../modals/modal/modal.component';
import { ResourcePreviewDTO } from '../resource-preview/resource-preview.dto';
import { SelectResourceComponent } from '../select-resource/select-resource.component';

@Component({
  selector: 'app-resource-search-modal',
  templateUrl: './resource-search-modal.component.html',
  styleUrls: ['./resource-search-modal.component.scss'],
})
export class ResourceSearchModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild('resourceSearchDescription')
  resourceSearchDescription: ElementRef<HTMLDivElement>;

  @ViewChild('resourceSearchModal') resourceSearchModal: ModalComponent;

  @ViewChild('resourceSelect') resourceSelect: SelectResourceComponent;

  @Input() userDistrictId: number | null;

  subs: Subscription[] = [];

  resources: ResourcePreviewDTO[] = [];

  resourceSearchModalConfig: ModalConfig = {};

  constructor(public coachlogResourceService: CoachingLogResourceService) {}

  ngAfterViewInit(): void {
    this.coachlogResourceService.addRefs(
      this.resourceSearchModal,
      this.resourceSearchDescription,
      this.resourceSelect
    );
  }

  ngOnDestroy(): void {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }

  removePreview(resourceId: number) {
    this.coachlogResourceService.removeResource(resourceId);
  }
}
