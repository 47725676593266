<div
  class="text-body-secondary d-sm-flex justify-content-between align-items-center mb-3"
  style="width: 100%">
  <div class="filter-bar d-flex flex-wrap flex-row align-items-center mt-1">
    <span class="filters-label">Applied Filters:</span>&nbsp; &nbsp;
    <ng-container *ngIf="searchFilters?.type && searchFilters?.type != ''">
      <div (click)="removeTypeFilter()" class="badge badge-item">
        <span class="fw-800"><i class="bi bi-code-square p-1"></i>Type: </span>
        <span *ngIf="searchFilters?.type == 'diy'">{{
          searchFilters.type | uppercase
        }}</span>
        <span *ngIf="searchFilters?.type !== 'diy'">{{
          searchFilters.type | titlecase
        }}</span>
        <i class="bi bi-x p-1 ms-1"></i>
      </div>
    </ng-container>

    <ng-container *ngIf="searchFilters?.created_by">
      <div class="badge badge-item" (click)="removeCreatedByFilter()">
        <span class="fw-800"><i class="bi bi-person p-1"></i>Created By: </span>
        <ng-container [ngSwitch]="searchFilters.created_by">
          <span *ngSwitchCase="'me'">Me</span>
          <span *ngSwitchCase="'e2l'">e2L Admin</span>
          <span *ngSwitchCase="'district'">My District</span>
          <span *ngSwitchDefault></span>
        </ng-container>
        <i class="bi bi-x p-1 ms-1"></i>
      </div>
    </ng-container>

    <ng-container *ngIf="searchFilters && searchFilters.tags">
      <div
        class="badge badge-item"
        (click)="removeTagFilter(tag)"
        *ngFor="let tag of searchFilters.tags">
        <span class="fw-800"><i class="bi bi-tag p-1"></i>Tag: </span>
        <span>{{ tag }}</span>
        <i class="bi bi-x p-1 ms-1"></i>
      </div>
    </ng-container>

    <ng-container *ngFor="let author of authors">
      <div
        *ngIf="author['name']"
        class="badge badge-item"
        (click)="removeAuthor(author)">
        <span class="fw-800"><i class="bi bi-person p-1"></i>Created By: </span>
        <span>{{ author["name"] }}</span>
        <i class="bi bi-x p-1 ms-1"></i>
      </div>
    </ng-container>

    <ng-container *ngFor="let competency of competencies">
      <div
        *ngIf="competency['title']"
        class="badge badge-item"
        (click)="removeCompetency(competency)">
        <span class="fw-800"
          ><i class="bi bi-file-earmark-check p-1"></i>Competency:
        </span>
        <span>{{ competency["title"] }}</span>
        <i class="bi bi-x p-1 ms-1"></i>
      </div>
    </ng-container>

    <ng-container *ngIf="searchFilters && searchFilters.levels">
      <div
        *ngFor="let level of searchFilters.levels"
        class="badge badge-item"
        (click)="removeLevelFilter(level)">
        <i class="bi bi-123 p-1"></i>
        <span>Level {{ level }}</span>
        <i class="bi bi-x p-1 ms-1"></i>
      </div>
    </ng-container>

    <ng-container *ngIf="searchFilters && searchFilters.endorsed_districts">
      <div
        *ngFor="let districtId of searchFilters.endorsed_districts"
        class="badge badge-item"
        (click)="removeEndorsedDistrict(districtId)">
        <ng-container *ngIf="districtId === 2; else district">
          <img
            src="assets/e2l-logo.png"
            alt="e2l"
            role="image"
            class="e2l-logo me-1" />
          <span>e2L-Recommended</span>
        </ng-container>
        <ng-template #district>
          <i class="bi bi-geo-alt me-1"></i>
          <span><app-district-name [districtId]="districtId" />Approved</span>
        </ng-template>
        <i class="bi bi-x p-1"></i>
      </div>
    </ng-container>

    <app-resource-filter-modal
      #filterModal
      [filterType]="'Sort By'"
      [user]="user"
      (currentCompetenciesEvent)="handleCompetencies($event)"
      (currentAuthorsEvent)="handleAuthors($event)"></app-resource-filter-modal>
  </div>
</div>
