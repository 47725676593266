<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-container>
  <ng-select
    [labelForId]="labelId"
    (change)="logTypeSelected($event); select.blur()"
    [items]="logTypeList"
    bindLabel="title"
    [loading]="searchLoading"
    [searchable]="!simpleSelect"
    [(ngModel)]="internalSelectedLogType"
    [placeholder]="placeholder"
    [ngStyle]="
      invalid ? { border: '1px solid red', 'border-radius': '5px' } : {}
    "
    #select>
  </ng-select>
  <ng-container
    *ngIf="internalSelectedLogTypeList && internalSelectedLogTypeList.length">
    <div>
      <ng-container *ngFor="let eachLogType of internalSelectedLogTypeList">
        <span
          class="badge badge-item mt-2"
          (click)="removeLogTypeFromList(eachLogType)"
          >{{ eachLogType.title }}<i class="bi bi-x mx-2"></i
        ></span>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
