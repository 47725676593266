import { User } from 'src/app/common/state/user/user.model';
import { UserRole, compareRoles } from '../state/user/role/user-role';

export const checkIfE2L = (userData: User) => {
  if (userData.roles) {
    // eslint-disable-next-line no-restricted-syntax
    for (const role of userData.roles) {
      if (role === UserRole.E2L_EMPLOYEE) {
        return true;
      }
    }
  }
  return false;
};

// If user is operator and not E2L employee
export const checkIfDistrictOperator = (userData: User) =>
  !userData.roles?.includes(UserRole.E2L_EMPLOYEE) &&
  userData.roles?.includes(UserRole.OPERATOR);

export const checkIfE2LOperator = (userData: User) =>
  userData.roles?.includes(UserRole.E2L_EMPLOYEE) &&
  userData.roles?.includes(UserRole.OPERATOR);

export const checkIfB2b = (userData: User) =>
  !!(
    userData.district?.isB2b &&
    compareRoles(
      [UserRole.DISTRICT_ADMIN, UserRole.COACH, UserRole.SCHOOL_ADMIN],
      userData.roles
    )
  );
