<button type="button" class="btn btn-link" (click)="resourceFilterModal.open()">
  Edit Filters
</button>
<app-modal
  #resourceFilterModal
  [modalConfig]="{
    titleText: 'Filter Results',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
      }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="resourceFilterModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div class="container">
    <h2>Resource Type</h2>
    <ng-select
      [items]="resourceTypeSelectValues"
      bindLabel="label"
      bindValue="value"
      [clearable]="false"
      [(ngModel)]="editedFilters.type"
      placeholder="Select Type"
      class="mb-3">
    </ng-select>

    <h2>Created By</h2>
    <div class="mb-3">
      <ng-select
        [items]="createdBySelectValues"
        bindLabel="label"
        bindValue="value"
        [clearable]="false"
        [(ngModel)]="editedFilters.created_by"
        (ngModelChange)="$event !== 'specific' ? (currentAuthors = []) : null"
        placeholder="Select Created By"
        class="mb-1">
      </ng-select>
      <div *ngIf="editedFilters.created_by === 'specific'">
        <app-user-select
          [clearAfterSelection]="true"
          (selectedUser)="addUser($event)">
        </app-user-select>
        <ng-container *ngIf="currentAuthors.length > 0">
          <div
            class="d-inline-block mt-1"
            *ngFor="let author of currentAuthors">
            <div class="badge badge-item" (click)="removeUser(author)">
              <span class="text-nowrap">{{ author.name }}</span>
              <i class="bi bi-x pt-1 ml-1"></i>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <h2>Tags</h2>
    <div class="mb-3">
      <app-select-tags
        [clearAfterSelect]="true"
        [placeholder]="'Select tag'"
        (afterSelection)="addTag($event)">
      </app-select-tags>
      <ng-container *ngIf="currentTags.length > 0">
        <div class="d-inline-block mt-1" *ngFor="let tag of currentTags">
          <div class="badge badge-item" (click)="removeTag(tag.tag)">
            <span class="text-nowrap">{{ tag.tag }}</span>
            <i class="bi bi-x pt-1 ml-1"></i>
          </div>
        </div>
      </ng-container>
    </div>
    <h2>Competency</h2>
    <div class="mb-3">
      <app-competency-select
        #selectCompetency
        [districtIds]="districtIds"
        [clearAfterSelection]="false"
        [isMulti]="true"
        [defaultCompetencies]="currentCompetencies"
        (selectedCompetencyList)="addCompetencies($event)"
        [placeholder]="'Select competency'" />
    </div>
    <div class="mb-3">
      <h2>Level</h2>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          name="levelCheckboxFilter"
          id="levelCheckboxFilter1"
          value="1"
          [checked]="editedFilters.levels?.includes(1)"
          (change)="onCheckboxChange($event)" />
        <label class="form-check-label" for="levelCheckboxFilter1">
          Level 1
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          name="levelCheckboxFilter"
          id="levelCheckboxFilter2"
          value="2"
          [checked]="editedFilters.levels?.includes(2)"
          (change)="onCheckboxChange($event)" />
        <label class="form-check-label" for="levelCheckboxFilter2">
          Level 2
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          name="levelCheckboxFilter"
          id="levelCheckboxFilter3"
          value="3"
          [checked]="editedFilters.levels?.includes(3)"
          (change)="onCheckboxChange($event)" />
        <label class="form-check-label" for="levelCheckboxFilter3">
          Level 3
        </label>
      </div>
      <div class="form-check mb-3">
        <input
          class="form-check-input"
          type="checkbox"
          name="levelCheckboxFilter"
          id="levelCheckboxFilter4"
          value="4"
          [checked]="editedFilters.levels?.includes(4)"
          (change)="onCheckboxChange($event)" />
        <label class="form-check-label" for="levelCheckboxFilter4">
          Level 4
        </label>
      </div>
      <div class="mb-3">
        <h2>Resource Endorsement</h2>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name="resourceEndorsementFilter"
            id="resourceEndorsementFilterE2l"
            value="2"
            [checked]="e2lRecommended"
            (change)="onEndorsementChange($event, 'e2l')" />
          <label class="form-check-label" for="resourceEndorsementFilterE2l">
            e2L-Recommended
          </label>
        </div>
        <div class="form-check mb-2">
          <input
            *ngIf="user && user.district"
            class="form-check-input"
            type="checkbox"
            name="resourceEndorsementFilter"
            id="resourceEndorsementFilterDistrict"
            [value]="user.district.id"
            [checked]="districtApproved"
            (change)="onEndorsementChange($event, 'district')" />
          <label
            class="form-check-label"
            for="resourceEndorsementFilterDistrict">
            District-Approved
          </label>
        </div>
        <app-district-select
          #districtSelect
          *ngIf="isE2l && districtApproved"
          [isMulti]="true"
          (selectedDistrictList)="onEndorsedDistrictListEvent($event)" />
      </div>
    </div>
  </div>
  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="resetFilters()">
        Clear All
      </button>
      <button
        type="button"
        class="btn btn-primary"
        id="apply-button"
        (click)="applyFilters()">
        Apply
      </button>
    </div>
  </div>
</app-modal>
