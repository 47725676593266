import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResourceLeveledListItemDTO } from '../../../dtos/resources.dto';
import { CoachingLogResourceService } from '../../../services/coaching-log/coaching-log-resource.service';
import {
  checkIfDistrictApproved,
  checkIfE2lRecommended,
} from '../../../services/resources/resource.helpers';

@Component({
  selector: 'app-resource-leveled',
  templateUrl: './resource-leveled.component.html',
  styleUrls: ['./resource-leveled.component.scss'],
})
export class ResourceLeveledComponent implements OnInit {
  @Input() resource: ResourceLeveledListItemDTO;

  @Input() level: number;

  @Input() limitDescriptionLength = true;

  @Input() showRemovalIcon = false;

  @Input() userDistrictId: number | null;

  @Output() readonly signalSelection: EventEmitter<number> = new EventEmitter();

  description: string;

  constructor(private coachlogResourceService: CoachingLogResourceService) {}

  ngOnInit() {
    this.description = this.limitDescriptionLength
      ? `${this.resource.description.substring(0, 70)} ...`
      : this.resource.description;

    if (this.resource.endorsements && this.userDistrictId) {
      this.resource.e2lRecommended = checkIfE2lRecommended(
        this.resource.endorsements
      );
      this.resource.districtApproved = checkIfDistrictApproved(
        this.resource.endorsements,
        this.userDistrictId
      );
    }
  }

  addItem() {
    this.coachlogResourceService.addLeveledResource(this.resource);
    this.signalSelection.next(this.resource.id);
  }
}
