<app-modal #resourceSearchModal [ngClass]="['mw800']">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="resourceSearchModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div #resourceSearchDescription>
    <p class="text-center fs1"><strong></strong><br /><span></span></p>
  </div>
  <app-resource-leveled-list
    [resources]="coachlogResourceService.leveledResources"
    [userDistrictId]="userDistrictId"
    [levelNumber]="
      coachlogResourceService.levelNumber
    "></app-resource-leveled-list>
  <hr />
  <div class="my-4">
    <app-select-resource
      #resourceSelect
      [clearAfterSelect]="true"
      [userDistrictId]="userDistrictId"
      placeholder="Search for Resources"></app-select-resource>
  </div>
  <hr />
  <h5 class="fw-800">Selected Resources</h5>
  <app-resource-preview-list
    [resources]="coachlogResourceService.resourcePreviews"
    [showRemovalIcon]="true"
    [userDistrictId]="userDistrictId"
    (signalRemoval)="removePreview($event)"></app-resource-preview-list>
</app-modal>
