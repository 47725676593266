import { CoachingSessionUserType } from 'src/app/private/shared/enums/coaching-session-user-type.enum';

import { CohortDTO } from 'src/app/common/dtos/cohort.dto';
import { DistrictDTO } from '../../../../common/dtos/district.dto';
import { ProfileDTO } from '../../../../common/dtos/profile.dto';
import { UserDTO } from '../../../../common/state/user/user.dto';
import { ApiResponseTemplate } from '../../../../common/types/responses/responses-template';
import { BooleanNumber } from '../../../../common/utilities/enums/boolean-number.enum';
import { BadgeDataDTO } from '../../dtos/badge.dto';
// eslint-disable-next-line import/no-cycle
import { CoachingLogType } from '../../dtos/coaching-session.dto';
import {
  ResourceFolderModelDTO,
  ResourceListItemDTO,
} from '../../dtos/resources.dto';
import { WillAPIResponse } from '../payloads/coaching-log.payloads';
import { UserAPIResponse } from './user.responses';
import { VideoAPIResponse } from './video.responses';

export interface AssignedCoacheesAPIResponse extends ApiResponseTemplate {
  items: CoacheeAPIResponse[];
}

export interface AssignedCoacheesDTOResponse extends ApiResponseTemplate {
  items: CoacheeDTO[];
}

export interface CoacheeAPIResponse {
  id: number;
  username: string;
  profile: ProfileDTO;
  badgeCount: number;
  celebrationsCount: number;
  sessionCount: number;
  classroomvisitCount: number;
  nextSessionDate: number | null;
  nextSessionId: number | null;
  personalNotes: string | null;
  cohorts: CohortDTO[];
}

export interface CoacheeDTO
  extends Omit<CoacheeAPIResponse, 'classroomvisitCount'> {
  observationsCount: number;
}

export const translateCoacheeAPIResponseToDTO = (
  response: CoacheeAPIResponse
): CoacheeDTO => ({
  ...response,
  observationsCount: response.classroomvisitCount,
});

export interface ResourceAPIResponse {
  item: ResourceAPIResponseItem;
}

export interface ResourceAPIResponseItem {
  created_at: number;
  egrowe_coachlog_option_id: string;
  elearn_resource_id: string;
  id: number;
  resource: ResourceListItemDTO;
}

export interface PusherResourceAPIResponseItem {
  id: number;
  title: string;
  description: string;
  content: string;
  type: string;
  slug: string;
  creator_user_id: number;
  created_at: number;
  updated_at: number;
  is_deleted: number;
  can_print: number;
  can_download: number;
  view_count: number;
  lock_user_id: null;
  lock_expires: null;
  userFavoriteShelves: ResourceFolderModelDTO[];
  isOnUsersShelf: number;
  url: string;
}

export interface CreatedCoachingLogAPIResponse {
  item: {
    content: string;
    created_at: number;
    creator_user_id: number;
    district_id: number;
    egrowe_coachlog_type_id: number;
    end_datetime: number;
    id: number;
    isCompleted: boolean;
    is_practice: number;
    location: string;
    start_datetime: number;
    timezone: string;
    title: string;
    updated_at: number;
    user_id: string;
    version: number;
  };
}

export interface CoachingSessionDataAPIResponse {
  id: number;
  title: string;
  content: string;
  egrowe_coachlog_type_id: number;
  start_datetime: number;
  end_datetime: number;
  user_id: number;
  creator_user_id: number;
  is_practice: BooleanNumber;
  is_private: BooleanNumber;
  version: number;
  timezone: string;
  type: CoachingSessionTypeAPIResponse;
  district: DistrictDTO;
  user: UserDTO;
  attendees: CoachingSessionAttendeesAPIResponse[];
  shadowers: CoachingSessionShadowerAPIResponse[];
  isCompleted: boolean;
  current_users_role: CoachingSessionUserType;
  attachments?: EvidenceAttachmentAPIResponse[];
  percent_complete: number;
}

export interface CoachingSessionTypeAPIResponse {
  created_at: number;
  description: string;
  id: number;
  include_egrowe_process: BooleanNumber;
  include_rubric_form: BooleanNumber;
  is_classroomvisit: BooleanNumber;
  is_coaching: BooleanNumber;
  is_smart: BooleanNumber;
  option_requires_standard: BooleanNumber;
  title: string;
  updated_at: number;
  view: CoachingLogType;
}

export interface IndividualCoachingSessionDataAPIResponse {
  item: CoachingSessionDataAPIResponse;
}

export interface UpcomingCoachingSessionsDataAPIResponse {
  items: CoachingSessionDataAPIResponse[];
}

export interface CoachingSessionAttendeesAPIResponse {
  id: number;
  user_id: number;
  egrowe_coachlog_id: number;
  present: BooleanNumber;
  created_at: number;
  updated_at: number;
  user: UserDTO;
  notes: string;
  rating: number | null;
  self_rating: number | null;
  absent_reason: number | null;
}

export interface CoachingSessionUpdateShadowerPusherResponse {
  item: CoachingSessionShadowerAPIResponse;
}

export interface CoachingSessionShadowerAPIResponse {
  id: number;
  user_id: number;
  egrowe_coachlog_id: number;
  present: number;
  created_at: number;
  updated_at: number;
  user: UserDTO;
}

export interface CoachingSessionDeleteShadowerPusherResponse {
  item: {
    id: number;
    egrowe_coachlog_id: number;
  };
}

export interface AttendeeRubricsAPIResponse {
  items: {
    egrowe_coachlog_id: number;
    rubrics: RubricAPIResponse[];
    user_id: number;
  }[];
}

export interface RubricAPIResponse {
  id: number;
  standardGroups: StandardGroupAPIResponse[];
  title: string;
}

export interface StandardGroupAPIResponse {
  id: number;
  standards: StandardAPIResponse[];
  title: string;
}

export interface StandardAPIResponse {
  completed_pre_assessment: boolean;
  content: string;
  faq_resource_slug: string;
  id: number;
  indicatorSet: IndicatorSetAPIResponse;
  title: string;
  level1video: VideoAPIResponse;
  level2video: VideoAPIResponse;
  level3video: VideoAPIResponse;
  level4video: VideoAPIResponse;
}

export interface IndicatorSetAPIResponse {
  id: number;
  indicatorGroups: IndicatorGroupsAPIResponse[];
  level1_name: string;
  level2_name: string;
  level3_name: string;
  level4_name: string;
  title: string;
}

export interface IndicatorGroupsAPIResponse {
  id: number;
  level1: LevelAPIResponse;
  level2: LevelAPIResponse;
  level3: LevelAPIResponse;
  level4: LevelAPIResponse;
  title: string;
}

export interface LevelAPIResponse {
  evidences: EvidenceAPIResponse[];
  goal: GoalAPIResponse;
  highestOptionLevel: number;
  indicators: IndicatorAPIResponse[];
  options: OptionAPIResponse[];
}

export interface EvidenceAPIResponse {
  id: number;
  added_from: string;
  assessments: EvidenceAssessmentAPIResponse[];
  content: string;
  created_at: number;
  district_id: number;
  egrowe_coachlog_id: number;
  egrowe_coachlog_option_id: number;
  egrowe_indicator_group_id: number;
  egrowe_rubric_id: number;
  egrowe_standard_id: number;
  evidenceAttachments: EvidenceAttachmentAPIResponse[];
  is_deleted: BooleanNumber;
  level: number;
  narrative: string;
  nearest_coachlog_id: number | null;
  title: string;
  type: string;
  updated_at: number;
  user_id: number;
  videos?: VideoAPIResponse[];
}

export interface EvidenceWithStandardAPIResponse extends EvidenceAPIResponse {
  standard: CompetencyStandardAPIResponse;
}

export interface GoalAPIResponse {
  id: number;
  user_id: number | string;
  egrowe_standard_id: number | string;
  goal_level: number;
  created_at: number;
  updated_at: number;
  egrowe_indicator_group_id: number | string;
  egrowe_coachlog_id: number | string;
}

export interface IndicatorAPIResponse {
  id: number;
  content: string;
  assessments: AssessmentAPIResponse[];
  requirements: [];
}

export interface CreatedUpdatedOptionAPIResponse {
  id: number;
  content: string;
  egrowe_coachlog_id: number;
  egrowe_indicator_group_id: number;
  egrowe_rubric_id: number;
  egrowe_standard_id: number;
  level: number;
  from_bank_option?: number;
  from_curated_option_id?: number;
  resources: PusherResourceAPIResponseItem[];
}

export interface OptionAPIResponse {
  content: string;
  fromCuratedOption: boolean | null;
  from_bank_option: number;
  from_curated_option_id: number | null;
  id: number;
  resources: ResourceListItemDTO[] | null;
  will: WillAPIResponse;
  evidence: EvidenceAPIResponse[];
}

export interface EvidenceAttachmentAPIResponse {
  id: number;
  type: string;
  relational_id: number;
  title: string;
  description: string;
  file_path: string;
  file_type: string;
  created_at: number;
  updated_at: number;
  user_id: number;
}

export interface EvidenceAssessmentAPIResponse {
  id: number;
  egrowe_evidence_id: number;
  assessor_user_id: number;
  title: string;
  content: string;
  approved: boolean;
  created_at: number;
  updated_at: number;
  assessor: UserDTO;
}

export interface AssessmentAPIResponse {
  id: number;
  assessment_type: string;
  egrowe_indicator_id: number;
  assessor_user_id: number;
  assessee_user_id: number;
  district_id: number;
  created_at: number;
  updated_at: number;
  egrowe_rubric_id: number;
  egrowe_coachlog_id: number;
  nearest_coachlog_id: number;
  main_coachlog_id: number;
  assessor: UserDTO;
}

export interface CompetenciesListAPIResponse {
  items: {
    id: number;
    egrowe_standard_group_id: number;
    title: string;
    content: string;
  }[];
}

export interface AbsentReasonsAPIResponse {
  created_at: number;
  id: number;
  is_deleted: number;
  order: number;
  title: string;
  updated_at: number;
}

export interface CoachlogTypesAPIResponse {
  id: string;
  title: string;
  description: string;
  include_egrowe_process: number;
  include_rubric_form: number;
  created_at: number;
  updated_at: number;
  option_requires_standard: number;
  is_coaching: number;
  is_classroomvisit: number;
  view: string;
  is_smart: number;
  districtAssigned: [];
  districts: [];
  excludedDistrict: [];
  districtsForbidden: [];
}

export interface FeedbackAPIResponse {
  content: string;
  created_at: number;
  egrowe_coachlog_id: number;
  id: number;
  is_deleted: BooleanNumber;
  updated_at: number;
  user: UserAPIResponse;
  user_id: number;
}

export interface BadgeUserAPIResponse extends UserAPIResponse {
  badges: BadgeDataDTO[];
}

export interface BadgeAPIResponse {
  assessment_type: string;
  badge_set_id: number;
  category: string;
  color: string;
  created_at: number;
  description: string;
  district_id: number;
  goal_type: string;
  hide_requirements: BooleanNumber;
  id: number;
  image: string;
  indicator_group_id: number;
  is_global: BooleanNumber;
  level: number;
  requirements: string;
  rubric: BadgeRubricAPIResponse;
  rubric_id: number;
  sorting: number;
  standard_id: number;
  sub_goal_type: string;
  title: string;
  updated_at: number;
}

export interface BadgeRubricAPIResponse {
  content: string;
  created_at: number;
  creator_user_id: number;
  district_id: number;
  egrowe_standard_set_id: number;
  id: number;
  is_calibration: BooleanNumber;
  is_deleted: BooleanNumber;
  is_fields_observed: BooleanNumber;
  is_smart: BooleanNumber;
  title: string;
  updated_at: number;
}

export interface NextSessionAPIResponse {
  log_id: number;
  coach_id: number;
  title: string;
  start_datetime: number;
  end_datetime: number;
  coachee_id: number;
  coachlog_type: string;
  timezone: string;
  egrowe_timeline_id: number;
  coachee_name: string;
}

export interface CompetenciesAPIResponse {
  content: string;
  created_at: number;
  creator_user_id: number;
  district_id: number;
  egrowe_standard_set_id: number;
  id: number;
  is_calibration: BooleanNumber;
  is_deleted: BooleanNumber;
  is_fields_observed: BooleanNumber;
  is_smart: BooleanNumber;
  title: string;
  standardSet: StandardSetAPIResponse;
  updated_at: number;
  icon?: string;
}

export interface StandardSetAPIResponse {
  id: number;
  title: string;
  content: string;
  abbreviation: string;
  created_at: number;
  updated_at: number;
  district_id: number;
  is_deleted: number;
  icon: string;
  for_students: number;
  standardGroups: CompetencyStandardGroupAPIResponse[];
}

export interface CompetencyStandardGroupAPIResponse {
  id: number;
  standards: CompetencyStandardsAPIResponse[];
  title: string;
  content: string;
  abbreviation: string;
  created_at: number;
  updated_at: number;
  is_deleted: number;
  egrowe_standard_set_id: number;
  sort_order: 1000;
  subject_id: number;
}

export interface CompetencyStandardsAPIResponse {
  abbreviation: string;
  id: number;
  standards: CompetencyStandardAPIResponse[];
  title: string;
  content: string;
  created_at: number;
  updated_at: number;
}

export interface CompetencyStandardAPIResponse {
  abbreviation: string;
  content: string;
  created_at: number;
  egrowe_standard_group_id: number;
  elearn_draft_standard_resource_id: number;
  elearn_standard_resource_id: number;
  grade_id: number;
  icon: string;
  id: number;
  is_custom: number;
  is_deleted: number;
  last_locked_datetime: number;
  locked_by_user_id: number;
  order: number;
  overviewVideo?: VideoAPIResponse;
  subject_ext: string;
  subject_id: number;
  title: string;
  updated_at: number;
  user_id: number;
}

export interface SessionPercentageAPIResponse {
  percentCompleted: number;
  competencyAdded: boolean;
  optionAdded: boolean;
  commitment: boolean;
  plannedEvidence: boolean;
}

export interface CardReflectionAPIResponse {
  confidence: number;
  effectiveness: number;
}
