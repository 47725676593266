<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-container>
  <ng-select
    [labelForId]="labelId"
    (change)="competencySelected($event); select.blur()"
    [items]="competencyList | async"
    bindLabel="title"
    [clearable]="true"
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="searchInput$"
    [loading]="searchLoading"
    [(ngModel)]="internalSelectedCompetency"
    [placeholder]="placeholder"
    [groupBy]="'rubric_name'"
    [disabled]="isDisabled"
    #select>
  </ng-select>

  <ng-container
    *ngIf="
      !hideTags &&
      internalSelectedCompetencyList &&
      internalSelectedCompetencyList.length
    ">
    <div>
      <ng-container
        *ngFor="let eachCompetency of internalSelectedCompetencyList">
        <div class="badge badge-item mt-2">
          <span class="text-content">{{ eachCompetency.title }}</span>
          <i
            class="bi bi-x close-icon"
            (click)="removeCompetencyFromList(eachCompetency)"></i>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
