import {
  ResourceEndorsementDTO,
  ResourceFiltersDTO,
} from '../../dtos/resources.dto';

export const defaultSearchFilterFreeze = (): ResourceFiltersDTO => ({
  created_by: '',
  type: '',
  order: 'search_score',
  authors: [],
  tags: [],
  competencies: [],
  levels: [],
  endorsed_districts: [],
});

export const defaultResourceListFilters: ResourceFiltersDTO = {
  created_by: '',
  type: '',
  order: 'search_score',
  authors: [],
  tags: [],
  competencies: [],
  levels: [],
  limit_to_shelf: 0,
};

export const checkIfE2lRecommended = (endorsements: ResourceEndorsementDTO[]) =>
  endorsements.filter((item) => item.district_id === 2).length > 0;

export const checkIfDistrictApproved = (
  endorsements: ResourceEndorsementDTO[],
  userDistrictId: number
) => {
  if (userDistrictId === 2) {
    return endorsements.filter((item) => item.district_id !== 2).length > 0;
  }
  return (
    endorsements.filter(
      (item) => item.district_id !== 2 && item.district_id === userDistrictId
    ).length > 0
  );
};
