<div *ngIf="strand" class="container">
  <div class="row mt-3 mb-2">
    <div class="col-4 pe-0">
      <input
        [(ngModel)]="strand.title"
        (input)="onTitleInput()"
        (keydown)="updatingEvent.emit('strandTitle'); currentStrand = true"
        [disabled]="apiLoading && !currentStrand"
        placeholder="Strand Name"
        type="text"
        class="form-control"
        id="strand-name"
        aria-label="strand-name-input" />
    </div>
    <div class="col ps-0">
      <button
        *ngIf="strand"
        type="button"
        class="btn btn-link text-decoration-none strand-title"
        (click)="isCollapsed = !isCollapsed">
        <i class="p-1 bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
      </button>
      <button
        class="btn btn-delete ms-2"
        ngbTooltip="Delete strand"
        [autoClose]="'outside'"
        [ngbPopover]="deleteStrandPopover"
        [popoverTitle]="'Delete Strand'"
        [disabled]="deleteStrandLoading || apiLoading"
        #popover="ngbPopover">
        <ng-container *ngIf="deleteStrandLoading; else deleteIcon">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </ng-container>
        <ng-template #deleteIcon>
          <i class="bi bi-trash"></i>
        </ng-template>
      </button>
      <ng-template #deleteStrandPopover>
        Permanently delete this strand?
        <div class="text-end mt-2">
          <button
            type="button"
            class="btn btn-secondary btn-sm px-3 m-1"
            (click)="popover.close()">
            No
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm px-3 m-1"
            (click)="
              popover.close();
              deleteStrandEvent.emit(strand.id);
              deleteStrandLoading = true
            ">
            Yes
          </button>
        </div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="strand">
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div class="container p-0">
        <div class="row g-lg-2 g-xxl-3 row-cols-lg-4 position-relative">
          <div class="col-lg mb-3">
            <app-builder-leveled-competency-strand-level
              (updatingEvent)="updatingEvent.emit($event)"
              (badgeGraphicUploadedEvent)="badgeGraphicUploadedEvent.emit()"
              [apiLoading]="apiLoading"
              [level]="1"
              [levelName]="level1name"
              [strand]="
                strand.indicators[0]
              "></app-builder-leveled-competency-strand-level>
          </div>
          <div class="col-lg mb-3">
            <app-builder-leveled-competency-strand-level
              (updatingEvent)="updatingEvent.emit($event)"
              (badgeGraphicUploadedEvent)="badgeGraphicUploadedEvent.emit()"
              [apiLoading]="apiLoading"
              [level]="2"
              [levelName]="level2name"
              [strand]="strand.indicators[1]"
              [badgeData]="
                strand.strandBadges[0]
              "></app-builder-leveled-competency-strand-level>
          </div>
          <div class="col-lg mb-3">
            <app-builder-leveled-competency-strand-level
              (updatingEvent)="updatingEvent.emit($event)"
              (badgeGraphicUploadedEvent)="badgeGraphicUploadedEvent.emit()"
              [apiLoading]="apiLoading"
              [level]="3"
              [levelName]="level3name"
              [strand]="strand.indicators[2]"
              [badgeData]="
                strand.strandBadges[1]
              "></app-builder-leveled-competency-strand-level>
          </div>
          <div class="col-lg mb-3">
            <app-builder-leveled-competency-strand-level
              (updatingEvent)="updatingEvent.emit($event)"
              (badgeGraphicUploadedEvent)="badgeGraphicUploadedEvent.emit()"
              [apiLoading]="apiLoading"
              [level]="4"
              [levelName]="level4name"
              [strand]="strand.indicators[3]"
              [badgeData]="
                strand.strandBadges[2]
              "></app-builder-leveled-competency-strand-level>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
