import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import {
  DistrictGoalItemDTO,
  DistrictSimpleDto,
} from 'src/app/common/dtos/district.dto';
import {
  KpiReportFilter,
  ReportApiResponse,
  ReportingGoalItemDTO,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { SchoolDTO, SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { translateKpiReportFilterFromFilterDTO } from 'src/app/common/dtos/translators/report-filters.translator';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { checkIfB2b, checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { v4 as uuidv4 } from 'uuid';
import { ChartType } from '../../shared/enums/chart-type.enum';
import { GoalType } from '../../shared/enums/goal-type.enum';
import { ReportPageFiltersService } from '../../shared/services/report/report-page-filters.service';
import { UnleashService } from '../../shared/services/unleash/unleash.service';
import { ReportingState } from '../../shared/state/reporting/reporting.state';

@Component({
  selector: 'app-reporting-page',
  templateUrl: './reporting-page.component.html',
  styleUrls: ['./reporting-page.component.scss'],
})
export class ReportingPageComponent implements OnInit, OnDestroy {
  user: User;

  isE2L = false;

  canFilterByDistrict = false;

  labelId = uuidv4();

  availableSchoolYears: string[] = [];

  schoolYearName: string;

  schoolYearItem: SchoolYearDTO;

  schoolYearId = 0;

  selectedSchool: SchoolDTO | null;

  selectedDistrict: DistrictSimpleDto | null;

  districtMissingYear = false;

  loadedDistrictInfo = false;

  isMultiDistrict = false;

  isLoadingGoals = false;

  isCollapsed = false;

  sessionsPerCoacheeGoal: ReportingGoalItemDTO | null;

  observationsPerCoacheeGoal: ReportingGoalItemDTO | null;

  celebrationsPerUserGoal: ReportingGoalItemDTO | null;

  badgesPerCoacheePerSessionGoal: ReportingGoalItemDTO | null;

  skillAttainmentGoal: ReportingGoalItemDTO | null;

  schoolFilter: KpiReportFilter = {
    codename: 'school',
    title: 'School',
    value: [''],
    displayName: 'School',
  };

  strandFilter: KpiReportFilter = {
    codename: 'is_strand',
    value: ['1'],
  };

  microcredentialFilter: KpiReportFilter = {
    codename: 'is_competency',
    value: ['1'],
  };

  yearToDateSessionParams: ReportRequestParams;

  yearToDateObservationParams: ReportRequestParams;

  yearToDateStrandBadgeParams: ReportRequestParams;

  yearToDateMicrocredentialParams: ReportRequestParams;

  yearToDateGIParams: ReportRequestParams;

  yearToDateCelebrationParams: ReportRequestParams;

  yearToDateCampusParams: ReportRequestParams;

  peopleCoachedParams: ReportRequestParams;

  otherSupportSessionsParams: ReportRequestParams;

  defaultGoals = [
    { goal: GoalType.Coaching, value: 7 },
    { goal: GoalType.Observations, value: 5 },
    { goal: GoalType.Celebrations, value: 2 },
    { goal: GoalType.GrowthRate, value: 1 },
  ];

  chartTypes = ChartType;

  defaultFilters: KpiReportFilter[];

  filtersWithSessionType: KpiReportFilter[];

  filtersWithCompetency: KpiReportFilter[];

  filtersSet = false;

  _subs: Subscription[] = [];

  lastUpdatedDate: string;

  currentMonth: string;

  sessionsKpiLoaded = false;

  sessionsKpiValue: number;

  sessionsKpiMonthlyValue: number;

  sessionsKpiOnTrack: boolean;

  badgesPerCoacheeLoaded = false;

  badgesPerCoacheeValue: number;

  badgesPerCoacheeMonthlyValue: number;

  badgesPerCoacheeOnTrack: boolean;

  celebrationsPerUserLoaded = false;

  celebrationsPerUserValue: number;

  celebrationsPerUserMonthlyValue: number;

  celebrationsPerUserOnTrack: boolean;

  sessionsPerCoacheeLoaded = false;

  sessionsPerCoacheeValue: number;

  sessionsPerCoacheeMonthlyValue: number;

  sessionsPerCoacheeOnTrack: boolean;

  observationsPerCoacheeLoaded = false;

  observationsPerCoacheeValue: number;

  observationsPerCoacheeMonthlyValue: number;

  observationsPerCoacheeOnTrack: boolean;

  skillsAttainedLoaded = false;

  skillsAttainedValue: number;

  skillsAttainedMonthlyValue: number;

  skillsAttainedOnTrack: boolean;

  months: string[] = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  constructor(
    private store: Store,
    private reportFiltersService: ReportPageFiltersService,
    private apiCoreService: APICoreService,
    protected featureFlagService: UnleashService
  ) {
    const updatedDate$ = this.store.select(ReportingState.getUpdatedDate);
    this._subs.push(
      updatedDate$.subscribe((date) => {
        this.lastUpdatedDate = date;
      })
    );
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.isE2L = checkIfE2L(this.user);
    this.canFilterByDistrict = this.isE2L || checkIfB2b(this.user);
  }

  ngOnInit(): void {
    this.getCurrentMonth();

    this._subs.push(
      this.reportFiltersService.filters.subscribe((filters) => {
        if (filters.schoolYearFilter) {
          this.filtersSet = false;
          this.schoolYearId = filters.schoolYearFilter.id;
          this.defaultFilters = translateKpiReportFilterFromFilterDTO(filters);
          this.filtersWithSessionType = [
            ...this.defaultFilters,
            {
              codename: 'is_coaching',
              value: ['1'],
            },
            {
              codename: 'is_present',
              value: ['1'],
            },
          ];
          this.filtersWithCompetency = [
            ...this.defaultFilters,
            {
              codename: 'is_competency',
              value: ['1'],
            },
          ];

          const districtFilter = this.filtersWithSessionType.find(
            (obj) => obj.codename === 'district'
          ) as KpiReportFilter;

          if (districtFilter.value.length > 1) {
            this.isMultiDistrict = true;
            this.unsetDistrictGoals();
            this.unsetTrackData();
          } else {
            this.isMultiDistrict = false;
            this.unsetTrackData();
          }
          this.setDistrictGoals(Number(districtFilter.value[0]));

          this.setQuickStatRequests();

          setTimeout(() => {
            this.filtersSet = true;
          }, 0);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  getCurrentMonth() {
    const currentDate = new Date();
    const currentMonthName = currentDate.getMonth();
    this.currentMonth = this.months[currentMonthName];
  }

  unsetDistrictGoals() {
    this.sessionsPerCoacheeGoal = null;
    this.observationsPerCoacheeGoal = null;
    this.celebrationsPerUserGoal = null;
    this.badgesPerCoacheePerSessionGoal = null;
    this.skillAttainmentGoal = null;
  }

  unsetTrackData() {
    this.sessionsKpiValue = 0;
    this.sessionsKpiMonthlyValue = 0;
    this.sessionsKpiLoaded = false;

    this.badgesPerCoacheeValue = 0;
    this.badgesPerCoacheeMonthlyValue = 0;
    this.badgesPerCoacheeLoaded = false;

    this.celebrationsPerUserValue = 0;
    this.celebrationsPerUserMonthlyValue = 0;
    this.celebrationsPerUserLoaded = false;

    this.sessionsPerCoacheeValue = 0;
    this.sessionsPerCoacheeMonthlyValue = 0;
    this.sessionsPerCoacheeLoaded = false;

    this.observationsPerCoacheeValue = 0;
    this.observationsPerCoacheeMonthlyValue = 0;
    this.observationsPerCoacheeLoaded = false;

    this.skillsAttainedValue = 0;
    this.skillsAttainedMonthlyValue = 0;
    this.skillsAttainedLoaded = false;
  }

  setDistrictGoals(districtId: number) {
    this.isLoadingGoals = true;
    this.apiCoreService
      .getRequest(`growelab/district-goals/for-district/${districtId}`)
      .subscribe((results) => {
        this.sessionsPerCoacheeGoal = this.findDistrictGoal(
          GoalType.Coaching,
          results.items
        );

        if (this.sessionsKpiValue >= 0) {
          this.getSessionsKpiMonthlyValue(GoalType.Coaching, results.items);
        }

        this.observationsPerCoacheeGoal = this.findDistrictGoal(
          GoalType.Observations,
          results.items
        );

        this.celebrationsPerUserGoal = this.findDistrictGoal(
          GoalType.Celebrations,
          results.items
        );

        if (this.celebrationsPerUserValue >= 0) {
          this.getCelebrationsPerUserMonthlyValue(
            GoalType.Celebrations,
            results.items
          );
        }

        this.badgesPerCoacheePerSessionGoal = this.findDistrictGoal(
          GoalType.GrowthRate,
          results.items
        );

        if (this.badgesPerCoacheeValue >= 0) {
          this.getBadgesPerCoacheeMonthlyValue(
            GoalType.GrowthRate,
            results.items
          );
        }

        this.skillAttainmentGoal = this.findDistrictGoal(
          GoalType.SkillsAttained,
          results.items
        );

        this.isLoadingGoals = false;
      });
  }

  findDistrictGoal(
    goalTitle: string,
    goalItems: DistrictGoalItemDTO[]
  ): ReportingGoalItemDTO {
    const goal = goalItems.find(
      (goalItem) => goalItem.goal.title === goalTitle
    );

    if (goal) {
      return {
        title: goal.goal.title,
        description: goal.goal.description,
        value: Number(goal.value),
      };
    }
    const defaultGoal = this.defaultGoals.find(
      (goalObj) => goalObj.goal === goalTitle
    );
    return {
      title: defaultGoal?.goal as string,
      description: defaultGoal?.goal as string,
      value: defaultGoal?.value as number,
    };
  }

  setQuickStatRequests() {
    this.yearToDateSessionParams = {
      id: 'glance-coaching-conversations',
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [...this.defaultFilters],
    };

    this.yearToDateObservationParams = {
      id: 'glance-observations',
      dataset: 'fct_form_submission',
      measure: {
        type: 'count',
        field: '',
      },
      filters: [...this.defaultFilters],
    };

    this.yearToDateStrandBadgeParams = {
      id: 'glance-strand-badges-earned',
      dataset: 'fct_user_badge',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [...this.defaultFilters, this.strandFilter],
    };

    this.yearToDateMicrocredentialParams = {
      id: 'glance-microcredentials-earned',
      dataset: 'fct_user_badge',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [...this.defaultFilters, this.microcredentialFilter],
    };

    this.yearToDateGIParams = {
      id: 'glance-gis-earned',
      dataset: 'fct_indicator_assessment',
      measure: {
        type: 'count',
        field: '',
      },
      filters: [
        ...this.defaultFilters,
        { codename: 'assessment_type_id', value: ['9'] },
      ],
    };

    this.yearToDateCelebrationParams = {
      id: 'glance-celebrations',
      dataset: 'fct_user_celebration',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [...this.defaultFilters],
    };

    this.yearToDateCampusParams = {
      id: 'glance-schools-coached',
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count_distinct',
        field: 'school_id',
      },
      filters: [...this.defaultFilters],
    };

    this.peopleCoachedParams = {
      id: 'glance-staff-coached',
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count_distinct',
        field: 'user_id',
      },
      filters: [...this.defaultFilters],
    };

    this.otherSupportSessionsParams = {
      id: 'glance-other-support-sessions',
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [
        ...this.defaultFilters,
        {
          codename: 'is_coaching',
          value: ['0'],
        },
        {
          codename: 'is_observation',
          value: ['0'],
        },
        {
          codename: 'is_present',
          value: ['1'],
        },
      ],
    };
  }

  // ON TRACK VALUES
  setSessionsKpiValue(e: string) {
    if (e === '-') {
      this.sessionsKpiValue = 0;
    } else {
      this.sessionsKpiValue = Number(e);
    }
    this.calculateSessionsKpiOnTrack();
  }

  getSessionsKpiMonthlyValue(
    goalTitle: string,
    goalItems: DistrictGoalItemDTO[]
  ) {
    const goal = goalItems.find(
      (goalItem) => goalItem.goal.title === goalTitle
    );

    if (goal) {
      if (this.currentMonth) {
        const monthValue = `${this.currentMonth}_value` as keyof typeof goal;
        this.sessionsKpiMonthlyValue = Number(goal[monthValue] || 0);
      }
    }
  }

  calculateSessionsKpiOnTrack() {
    this.sessionsKpiOnTrack =
      this.sessionsKpiValue >= this.sessionsKpiMonthlyValue;
    this.sessionsKpiLoaded = true;
  }

  setBadgesPerCoacheeValue(e: string) {
    if (e === '-') {
      this.badgesPerCoacheeValue = 0;
    } else {
      this.badgesPerCoacheeValue = Number(e);
    }
    this.calculateBadgesPerCoacheeOnTrack();
  }

  getBadgesPerCoacheeMonthlyValue(
    goalTitle: string,
    goalItems: DistrictGoalItemDTO[]
  ) {
    const goal = goalItems.find(
      (goalItem) => goalItem.goal.title === goalTitle
    );

    if (goal) {
      if (this.currentMonth) {
        const monthValue = `${this.currentMonth}_value` as keyof typeof goal;
        this.badgesPerCoacheeMonthlyValue = Number(goal[monthValue] || 0);
      }
    }
  }

  calculateBadgesPerCoacheeOnTrack() {
    this.badgesPerCoacheeOnTrack =
      this.badgesPerCoacheeValue >= this.badgesPerCoacheeMonthlyValue;

    this.badgesPerCoacheeLoaded = true;
  }

  setCelebrationsPerUserValue(e: string) {
    if (e === '-') {
      this.celebrationsPerUserValue = 0;
    } else {
      this.celebrationsPerUserValue = Number(e);
    }
    this.calculateCelebrationsPerUserOnTrack();
  }

  getCelebrationsPerUserMonthlyValue(
    goalTitle: string,
    goalItems: DistrictGoalItemDTO[]
  ) {
    const goal = goalItems.find(
      (goalItem) => goalItem.goal.title === goalTitle
    );

    if (goal) {
      if (this.currentMonth) {
        const monthValue = `${this.currentMonth}_value` as keyof typeof goal;
        this.celebrationsPerUserMonthlyValue = Number(goal[monthValue] || 0);
      }
    }
  }

  calculateCelebrationsPerUserOnTrack() {
    this.celebrationsPerUserOnTrack =
      this.celebrationsPerUserValue >= this.celebrationsPerUserMonthlyValue;
    this.celebrationsPerUserLoaded = true;
  }

  getTrackData(apiResponse: ReportApiResponse) {
    if (apiResponse.id && apiResponse.series) {
      const attainedValue =
        apiResponse.series[0].data[apiResponse.series[0].data.length - 1];
      const monthlyValue =
        apiResponse.series[1].data[apiResponse.series[0].data.length - 1];

      if (apiResponse.id === 'coaching-conversations-chart') {
        this.sessionsPerCoacheeOnTrack = attainedValue >= monthlyValue;
        this.sessionsPerCoacheeLoaded = true;
      } else if (apiResponse.id === 'classroom-visits-chart') {
        this.observationsPerCoacheeOnTrack = attainedValue >= monthlyValue;
        this.observationsPerCoacheeLoaded = true;
      } else if (apiResponse.id === 'badges-per-coachee-chart') {
        this.skillsAttainedOnTrack = attainedValue >= monthlyValue;
        this.skillsAttainedLoaded = true;
      }
    }
  }
}
